import React, { useState, useEffect } from "react";
import * as api from "../../../../../services/ApiService";
import * as tokenUtils from "../../../../../utils/tokenUtils";
import { Link } from "react-router-dom";
import { formatDate } from "../../../../../utils/dateFormat";

function JobApplications({ profileInfo }) {
  // Get data from token util
  let user = tokenUtils.getTokenData();

  /* Filters function Start */
  const [showFilterDropdown, setFilterDropdown] = useState(false);
  const handleToggleFilterDropDown = () => {
    setFilterDropdown(!showFilterDropdown);
  };

  // State for filter values
  const [filters, setFilters] = useState({
    keyword: "",
    status: "all",
  });

  // Handle form reset
  const handleReset = () => {
    const defaultFilters = {
      keyword: "",
      status: "all",
    };
    setFilters(defaultFilters);
    setFilterDropdown(false);
  };

  useEffect(() => {
    if (showFilterDropdown === false) {
      fetchJobApplications();
    }
  }, [showFilterDropdown]);

  // Handle form input change
  const handleFilterInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setFilters({
        ...filters,
        [name]: checked,
      });
    } else {
      setFilters({
        ...filters,
        [name]: value,
      });
    }
  };

  // Handle form submit
  const handleFilterSubmit = (e) => {
    e.preventDefault();
    setFilterDropdown(false);
  };
  /* Filters function End */

  const [JobApplications, setJobApplications] = useState({});
  const [loading, setLoading] = useState(true);
  const [noRecordsFound, setNoRecordsFound] = useState(false);

  const fetchJobApplications = async () => {
    try {
      const param = {
        user_id: user.id,
        userID: profileInfo.id,
      };
      const queryParams = { ...param, ...filters };
      const apiData = await api.postMethod(queryParams, "admin/get-user-applications");

      setJobApplications(apiData.data);
      setLoading(false);
      setNoRecordsFound(false);
      // Check if data array is empty
      if (apiData.data.length === 0) {
        setNoRecordsFound(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchJobApplications();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const [jobAppStatus, setJobAppStatus] = useState([]);

  useEffect(() => {
    // Function to fetch data from the API
    const fetchJobAppStatus = async () => {
      try {
        const apiData = await api.getMethod("get-job-application-status");
        setJobAppStatus(apiData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchJobAppStatus();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  return (
    <>
      <div className="row justify-content-between mb-3">
        <div className="col-auto">
          <div className="dropdown" style={{ width: 300 }}>
            <button
              type="button"
              className="btn btn-outline-light dropdown-toggle w-100 text-start"
              onClick={handleToggleFilterDropDown}
            >
              Filters
              <i className="mdi mdi-chevron-down float-end" />
            </button>
            <div className={`dropdown-menu ${showFilterDropdown ? "show" : ""}`}>
              <form className="px-2 py-2" onSubmit={handleFilterSubmit}>
                <div className="mb-2">
                  <label htmlFor="keyword" className="form-label">
                    Keyword
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="keyword"
                    name="keyword"
                    value={filters.keyword}
                    onChange={handleFilterInputChange}
                  />
                </div>
                <div className="mb-2">
                  <label htmlFor="status" className="form-label">
                    Status
                  </label>
                  <select
                    className="form-control"
                    id="job_status"
                    name="status"
                    value={filters.status}
                    onChange={handleFilterInputChange}
                  >
                    <option value="all">Please Select</option>

                    {jobAppStatus.map((status) => (
                      <>
                        <option value={status.id} key={status.id}>
                          {status.title}
                        </option>
                      </>
                    ))}
                  </select>
                </div>
                <div className="text-end">
                  <button type="button" className="btn btn-light float-start" onClick={handleReset}>
                    Reset
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="col-auto" />
        {/* end col*/}
      </div>

      <div className="table-responsive">
        <table className="table table-centered table-nowrap mb-0">
          <thead className="table-light">
            <tr>
              <th>Job</th>
              <th>Status</th>
              <th>Applied On</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr key="0">
                <td colSpan="3">Loading...</td>
              </tr>
            ) : (
              <>
                {noRecordsFound ? (
                  <tr key="0">
                    <td colSpan="3">No job applicants found</td>
                  </tr>
                ) : (
                  <>
                    {Object.entries(JobApplications).map(([key, value]) => (
                      <tr key={value.job_id}>
                        <td>
                          <h5 className="m-0 font-weight-normal">
                            <Link to={`/admin/job/` + value.unique_id} className="text-dark">
                              {value.title}{" "}
                            </Link>
                            <span className="mb-0 text-muted">
                              <small>(#{value.unique_id})</small>
                            </span>
                          </h5>
                          <p className="mb-0 text-muted">
                            <small>
                              {value.state_code !== null && value.city_name !== null
                                ? value.city_name + ", " + value.state_code
                                : value.state_code !== null
                                ? value.state_code
                                : value.city_name !== null
                                ? value.city_name
                                : ""}
                            </small>
                          </p>
                        </td>
                        <td>
                          {value.status == 1 && (
                            <label className="badge badge-soft-primary">Applied</label>
                          )}
                          {value.status == 2 && (
                            <label className="badge badge-soft-info">Shortlisted</label>
                          )}
                          {value.status == 3 && (
                            <label className="badge badge-soft-warning">Submitted</label>
                          )}
                          {value.status == 4 && (
                            <label className="badge badge-soft-warning">Interviewing</label>
                          )}
                          {value.status == 5 && (
                            <label className="badge badge-soft-secondary">Offered</label>
                          )}
                          {value.status == 6 && (
                            <label className="badge badge-soft-success">Hired</label>
                          )}
                          {value.status == 7 && (
                            <label className="badge badge-soft-danger">Rejected</label>
                          )}
                        </td>
                        <td>
                          {formatDate(value.created_at, "MMM DD, YYYY")}{" "}
                          <small className="text-muted">
                            {formatDate(value.created_at, "hh:mm A")}
                          </small>
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default JobApplications;

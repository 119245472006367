import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../../../Layout/Header";
import Footer from "../../../Layout/Footer";
import Sidebar from "../../../Layout/Sidebar";
import ThemeSettings from "../../../Layout/ThemeSettings";
import * as api from "../../../../services/ApiService";
import * as Yup from "yup";
import * as tokenUtils from "../../../../utils/tokenUtils";
import Spinner from "../../../Loader/Spinner";
import PageTitle from "../../../Layout/PageTitle";
import { currentRole } from "../../../Layout/HelmetComponent";

function JobPostStep2() {
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { jobID } = useParams();

  // Get data from token util
  let user = tokenUtils.getTokenData();

  const [formData, setformData] = useState({
    step: "step2",
    id: null,
    user_id: user.id,
    token: user.token,
    state_id: null,
    city_id: null,
    salary_start_range: null,
    salary_end_range: null,
    salary_type: null,
    employment_type_id: null,
    min_work_per_week: null,
  });

  const fetchDratJobs = async () => {
    try {
      setIsLoading(true);
      const param = {
        step: "step2",
        user_id: user.id,
        token: user.token,
        job_id: localStorage.getItem("job_id"),
        jobID: jobID,
      };
      const apiData = await api.postMethod(param, "admin/get-draft-job");
      //const apiDataObj = Object.setPrototypeOf(apiData, Object.prototype);
      if (apiData.status) {
        /*
        // Save the data in local storage
        localStorage.setItem("job_id", apiData.data.id);
        navigate("/admin/job-post-step2");
        */
        setformData(apiData.data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        navigate("/admin/job-post-step1");
      }
      /*
      const apiDataObj = apiData.reduce((acc, currentValue, index) => {
        acc[index] = currentValue;
        return acc;
      }, {});
      */

      //setWorkHisstories(apiDataObj);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchDratJobs();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  //Define the validation schema
  const validationSchema = Yup.object({
    state_id: Yup.string().required("State is Required"),
    city_id: Yup.string().required("City is Required"),
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setformData({ ...formData, [name]: value });

    validateFields(name, value);
  };

  const validateFields = (name, value) => {
    try {
      validationSchema.validateSyncAt(name, { [name]: value });

      // Clear the error for the current field if validation passes
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    } catch (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error.message,
      }));
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      await validationSchema.validate(formData, { abortEarly: false });
      setIsLoading(true);
      const result = await api.postMethod(formData, "admin/post-job");
      if (result) {
        setIsLoading(false);
        if (jobID) navigate("/admin/job-post-step3/" + jobID);
        else navigate("/admin/job-post-step3");
      }
    } catch (error) {
      const newErrors = {};
      if (error.inner && Array.isArray(error.inner)) {
        error.inner.forEach((element) => {
          newErrors[element.path] = element.message;
        });
      } else {
        console.error("Unexpected error structure:", error);
      }
      setErrors(newErrors);
    }
  };

  const [states, setStates] = useState([]);
  const [employmentTypes, setEmploymentTypes] = useState([]);

  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        const apiData = await api.getMethod("get-states");
        setStates(apiData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchData();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const [cities, setCities] = useState([]);

  useEffect(() => {
    // Function to fetch data from the API
    const fetchCityData = async () => {
      try {
        const apiData = await api.getMethod("get-cities/" + formData.state_id);

        setCities(apiData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchCityData();
  }, [formData.state_id]); // Empty dependency array ensures that the effect runs only once, when the component mounts

  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        const apiData = await api.getMethod("get-employment-types");
        setEmploymentTypes(apiData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchData();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const breadcrumbItems = [
    { text: "Admin Dashboard", link: "/admin/dashboard" },
    { text: "Jobs", link: "/admin/jobs" },
    { text: "Post a Job", link: null },
  ];

  return (
    <>
      <div id="wrapper">
        <Sidebar />

        <div className="content-page">
          <Header />

          <div className="content">
            {/* Start Content*/}
            <div className="container-fluid">
              <PageTitle title="Post a Job" breadcrumbItems={breadcrumbItems} />
              {/*Job progress bar start*/}
              <div className="row">
                <div className="col-12">
                  <div className="stepper-wrapper">
                    <div className="stepper-item completed">
                      <div className="step-counter">1</div>
                      <div className="step-name">Position Details</div>
                    </div>
                    <div className="stepper-item active">
                      <div className="step-counter">2</div>
                      <div className="step-name">Location &amp; Salary</div>
                    </div>
                    <div className="stepper-item">
                      <div className="step-counter">3</div>
                      <div className="step-name">Qualification &amp; Responsibilities</div>
                    </div>
                    <div className="stepper-item">
                      <div className="step-counter">4</div>
                      <div className="step-name">Attatchments</div>
                    </div>
                  </div>
                </div>
              </div>
              {/*Job progress bar end*/}
              <div className="row">
                <div className="col-12">
                  <div className="card custom-box-shadow">
                    <div className="card-body">
                      <h4 className="header-title text-uppercase mb-2">Job Location and Salary</h4>
                      <p className="sub-header">Specify Job location and salary details</p>
                      <form onSubmit={handleFormSubmit}>
                        <div className="row">
                          <div className="form-group mb-3 col-md-6">
                            <label htmlFor="state_id">State *</label>
                            <select
                              id="state_id"
                              className="form-control"
                              name="state_id"
                              value={formData.state_id !== null ? formData.state_id : ""}
                              onChange={handleChange}
                            >
                              <option value="" key="">
                                Please Select
                              </option>
                              {/* Render the professions */}
                              {states.map((state) => (
                                <option key={state.id} value={state.id}>
                                  {state.name}
                                </option>
                              ))}
                            </select>
                            {errors.state_id && <span className="error">{errors.state_id}</span>}
                          </div>
                          <div className="form-group mb-3 col-md-6">
                            <label htmlFor="city_id">City *</label>
                            <select
                              id="city_id"
                              className="form-control"
                              name="city_id"
                              value={formData.city_id !== null ? formData.city_id : ""}
                              onChange={handleChange}
                            >
                              <option value="" key="">
                                Please Select
                              </option>
                              {/* Render the professions */}
                              {cities.map((city) => (
                                <option key={city.id} value={city.id}>
                                  {city.city_name}
                                </option>
                              ))}
                            </select>
                            {errors.city_id && <span className="error">{errors.city_id}</span>}
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group mb-3 col-md-6">
                            <label htmlFor="salary_start_range">Salary</label>
                            <div className="row">
                              <div className="col-12">
                                <div className="input-group">
                                  <div className="input-group-prepend">
                                    <span className="input-group-text" id="inputGroupPrepend">
                                      $
                                    </span>
                                  </div>
                                  <input
                                    type="number"
                                    className="form-control"
                                    id="salary_start_range"
                                    name="salary_start_range"
                                    defaultValue={formData.salary_start_range}
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="form-group mb-3 col-md-6">
                            <label htmlFor="salary_type">Salary/Budget Type</label>
                            <select
                              id="salary_type"
                              className="form-control"
                              name="salary_type"
                              value={formData.salary_type !== null ? formData.salary_type : ""}
                              onChange={handleChange}
                            >
                              <option value="" key="">
                                Please select
                              </option>
                              <option value="Hourly" key="Hourly">
                                Hourly
                              </option>
                              <option value="Weekly" key="Weekly">
                                Weekly
                              </option>
                              <option value="Monthly" key="Monthly">
                                Monthly
                              </option>
                              <option value="Yearly" key="Yearly">
                                Yearly
                              </option>
                            </select>
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group mb-3 col-md-6">
                            <label htmlFor="employment_type_id">Employment Type</label>
                            <select
                              id="employment_type_id"
                              className="form-control"
                              name="employment_type_id"
                              value={
                                formData.employment_type_id !== null
                                  ? formData.employment_type_id
                                  : ""
                              }
                              onChange={handleChange}
                            >
                              <option value="" key="">
                                Please select
                              </option>
                              {/* Render the professions */}
                              {employmentTypes.map((emp) => (
                                <option key={emp.id} value={emp.id}>
                                  {emp.title}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="form-group mb-3 col-md-6">
                            <label htmlFor="min_work_per_week">Minimum hour per Week</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="x days / x hours"
                              id="min_work_per_week"
                              name="min_work_per_week"
                              defaultValue={formData.min_work_per_week}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <Link
                              to="/admin/job-post-step1"
                              className="btn btn-secondary"
                              type="submit"
                            >
                              <i className="fas fa-angle-left" /> Back
                            </Link>
                          </div>
                          <div className="col-md-6">
                            <div className="text-lg-end">
                              <button className="btn btn-primary" type="submit">
                                Save and Continue <i className="fas fa-angle-right" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
      <ThemeSettings />
      {isLoading === true && <Spinner />}
    </>
  );
}

export default JobPostStep2;

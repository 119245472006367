import React, { useState, useEffect } from "react";
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import Sidebar from "../../Layout/Sidebar";
import ThemeSettings from "../../Layout/ThemeSettings";
import { Link } from "react-router-dom";
import * as api from "../../../services/ApiService";
import { Tooltip } from "react-tooltip";
import * as tokenUtils from "../../../utils/tokenUtils";
import * as Yup from "yup";
import Spinner from "../../Loader/Spinner";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import PageTitle from "../../Layout/PageTitle";
import { currentRole } from "../../Layout/HelmetComponent";
import { formatDate } from "../../../utils/dateFormat";
import useConfirm from "../../../hooks/useConfirm";

function ClientFacilityCompliances() {
  // Get data from token util
  let user = tokenUtils.getTokenData();

  const complianceTypes = ["Applicants", "Onboarding", "Working"];

  /* Filters function Start */
  const [showFilterDropdown, setFilterDropdown] = useState(false);
  const handleToggleFilterDropDown = () => {
    setFilterDropdown(!showFilterDropdown);
  };

  // State for filter values
  const [filters, setFilters] = useState({
    keyword: "",
    status: "all",
  });

  // Handle form reset
  const handleReset = () => {
    const defaultFilters = {
      keyword: "",
      status: "all",
    };
    setFilters(defaultFilters);
    setFilterDropdown(false);
  };

  useEffect(() => {
    if (showFilterDropdown === false) {
      fetchComplianceFiles();
    }
  }, [showFilterDropdown]);

  // Handle form input change
  const handleFilterInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setFilters({
        ...filters,
        [name]: checked,
      });
    } else {
      setFilters({
        ...filters,
        [name]: value,
      });
    }
  };

  // Handle form submit
  const handleFilterSubmit = (e) => {
    e.preventDefault();
    setFilterDropdown(false);
  };
  /* Filters function End */

  const [isLoading, setIsLoading] = useState(false);
  const [complianceFiles, setComplianceFiles] = useState({});
  const [loading, setLoading] = useState(true);
  const [noRecordsFound, setNoRecordsFound] = useState(false);

  const fetchComplianceFiles = async (tab) => {
    try {
      const param = {
        user_id: user.id,
      };
      const queryParams = { ...param, ...filters };
      const apiData = await api.postMethod(queryParams, "client/get-facility-compliances");

      setComplianceFiles(apiData.data);
      setLoading(false);
      // Check if data array is empty
      if (apiData.data.length === 0) {
        setNoRecordsFound(true);
      } else setNoRecordsFound(false);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchComplianceFiles();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const [formData, setformData] = useState({
    id: null,
    user_id: user.id,
    title: null,
    type: null,
    status: null,
  });

  const [uploadModalOpen, setUploadModalOpen] = useState(false);

  const toggleUploadModal = () => {
    setUploadModalOpen(!uploadModalOpen);

    if (!uploadModalOpen) {
      setformData({
        id: null,
        user_id: user.id,
        title: null,
        type: null,
        status: null,
      });

      // Reset validation errors
      setErrors({});
    }
  };

  const handleEdit = (record) => {
    // Populate formData with the values of the selected record
    setformData({
      user_id: user.id,
      id: record.facility_compliance_id,
      title: record.title,
      type: record.type,
      status: record.status,
    });

    setUploadModalOpen(true);
  };

  const { confirm } = useConfirm();
  const handleDelete = async (record) => {
    const confirmed = await confirm();
    if (confirmed.isConfirmed) {
      record.user_id = user.id;
      setIsLoading(true);
      const result = await api.postMethod(record, "client/delete-facility-compliance");
      setIsLoading(false);
      if (result.status) {
        toast.success(result.message);
        fetchComplianceFiles();
      }
    }
  };

  //Define the validation schema
  const validationSchema = Yup.object({
    title: Yup.string().required("Title is Required"),
    type: Yup.string().required("Type is Required"),
  });

  const [errors, setErrors] = useState({});
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await validationSchema.validate(formData, { abortEarly: false });

      setIsLoading(true);
      const result = await api.postFileMethod(formData, "client/update-facility-compliance");
      setIsLoading(false);
      if (result.status) {
        toast.success(result.message);

        //document.getElementById("file_name").value = "";
        setUploadModalOpen(false);
        fetchComplianceFiles();
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      setIsLoading(false);
      const newErrors = {};
      if (error.inner && Array.isArray(error.inner)) {
        error.inner.forEach((element) => {
          newErrors[element.path] = element.message;
        });
      } else {
        console.error("Unexpected error structure:", error);
      }
      setErrors(newErrors);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setformData({ ...formData, [name]: value });

    validateFields(name, value);
  };

  const validateFields = (name, value) => {
    try {
      validationSchema.validateSyncAt(name, { [name]: value });

      // Clear the error for the current field if validation passes
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    } catch (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error.message,
      }));
    }
  };

  const updateStatus = async (id, status) => {
    try {
      setIsLoading(true);
      const param = {
        user_id: user.id,
        id: id,
        status: status,
      };
      const result = await api.postFileMethod(param, "client/update-facility-compliance-status");
      setIsLoading(false);
      if (result.status) {
        toast.success(result.message);
        fetchComplianceFiles();
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const cloneFacilityCompliance = async (id) => {
    try {
      setIsLoading(true);
      const param = {
        user_id: user.id,
        id: id,
      };
      const result = await api.postFileMethod(param, "client/clone-facility-compliance");
      setIsLoading(false);
      if (result.status) {
        toast.success(result.message);
        fetchComplianceFiles();
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const [showAnnouncement, setShowAnnouncement] = useState(true);
  const [announcement, setAnnouncement] = useState(null);
  const fetchFacilityAnnouncementData = async () => {
    try {
      const result = await api.getMethod("get-announcement/facility-compliance");
      if (result.status) {
        const apiData = result.data;
        setAnnouncement(apiData.description);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchFacilityAnnouncementData();

    const storedShowAnnouncement = localStorage.getItem("showFacilityCompliancesAnnouncement");
    if (storedShowAnnouncement !== null) {
      setShowAnnouncement(JSON.parse(storedShowAnnouncement));
    }
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const handleDismiss = () => {
    setShowAnnouncement(false);
    localStorage.setItem("showFacilityCompliancesAnnouncement", false);
  };

  const handleShow = () => {
    setShowAnnouncement(true);
    localStorage.setItem("showFacilityCompliancesAnnouncement", true);
  };

  const breadcrumbItems = [
    { text: currentRole + " Dashboard", link: "/client/dashboard" },
    { text: "Compliance Files", link: "/client/compliance-files" },
    { text: "Facility Compliance Lists", link: null },
  ];

  return (
    <>
      <div id="wrapper">
        <Sidebar />

        <div className="content-page">
          <Header />
          <div className="content">
            <div className="d-inline-block w-100">
              {announcement && showAnnouncement && (
                <div
                  className="alert alert-info mt-3 mb-0 alert-dismissible fade show"
                  role="alert"
                >
                  <div dangerouslySetInnerHTML={{ __html: announcement }} />
                  <button type="button" className="btn-close" onClick={handleDismiss}></button>
                </div>
              )}

              {!showAnnouncement && (
                <>
                  <button
                    className="btn btn-info mt-3 float-end btn-rounded"
                    onClick={handleShow}
                    data-tooltip-id="mt-33"
                    data-tooltip-content="Show Announcement"
                  >
                    <i class="fas fa-info"></i>
                  </button>
                  <Tooltip id="mt-33"></Tooltip>
                </>
              )}
            </div>

            {/* Start Content*/}
            <div className="container-fluid">
              {/* start page title */}

              <PageTitle title="Facility Compliance Lists" breadcrumbItems={breadcrumbItems} />

              <div className="row">
                <div className="col-12">
                  <div className="card custom-box-shadow">
                    <div className="card-body">
                      <div className="row justify-content-between mb-2">
                        <div className="col-auto">
                          <div className="dropdown" style={{ width: 300 }}>
                            <button
                              type="button"
                              className="btn btn-outline-light dropdown-toggle w-100 text-start"
                              onClick={handleToggleFilterDropDown}
                            >
                              Filters
                              <i className="mdi mdi-chevron-down float-end" />
                            </button>
                            <div className={`dropdown-menu ${showFilterDropdown ? "show" : ""}`}>
                              <form className="px-2 py-2" onSubmit={handleFilterSubmit}>
                                <div className="mb-2">
                                  <label htmlFor="keyword" className="form-label">
                                    Keyword
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="keyword"
                                    name="keyword"
                                    value={filters.keyword}
                                    onChange={handleFilterInputChange}
                                  />
                                </div>

                                <div className="mb-2">
                                  <label htmlFor="status" className="form-label">
                                    Status
                                  </label>
                                  <select
                                    className="form-select"
                                    id="cstatus"
                                    name="status"
                                    value={filters.status}
                                    onChange={handleFilterInputChange}
                                  >
                                    <option value={"all"}>Please Select</option>
                                    <option value={1}>Active</option>
                                    <option value={0}>In-Active</option>
                                  </select>
                                </div>

                                <div className="text-end mt-1">
                                  <button
                                    type="button"
                                    className="btn btn-light float-start"
                                    onClick={handleReset}
                                  >
                                    Reset Filter
                                  </button>
                                  <button type="submit" className="btn btn-primary">
                                    Apply Filter
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                        <div className="col-auto">
                          <div className="text-lg-end my-1 my-lg-0">
                            <button
                              type="button"
                              className="btn btn-primary waves-effect waves-light mb-2 me-1"
                              onClick={toggleUploadModal}
                            >
                              <i className="fa fa-plus mr-1"></i> Add New Checklist
                            </button>

                            <Link
                              to="/client/assign-facility-compliance"
                              className="btn btn-info waves-effect waves-light mb-2"
                            >
                              <i className="fa fa-cog mr-1"></i> Assign Facility Compliance
                            </Link>
                          </div>
                        </div>
                        {/* end col*/}
                      </div>
                      <div className="table-responsive">
                        <table className="table table-centered table-nowrap mb-0">
                          <thead className="table-light">
                            <tr>
                              <th>Title</th>
                              <th>Type</th>
                              <th>Creation Date</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {loading ? (
                              <tr>
                                <td colSpan="5">Loading...</td>
                              </tr>
                            ) : (
                              <>
                                {noRecordsFound ? (
                                  <tr>
                                    <td colSpan="5">No records found</td>
                                  </tr>
                                ) : (
                                  <>
                                    {Object.entries(complianceFiles).map(([key, value]) => (
                                      <tr key={value.id}>
                                        <td>{value.title}</td>
                                        <td>{value.type}</td>
                                        <td>
                                          {formatDate(value.created_at, "MMM DD, YYYY")}{" "}
                                          <small className="text-muted">
                                            {formatDate(value.created_at, "hh:mm A")}
                                          </small>
                                        </td>
                                        <td>
                                          {value.status == 1 && (
                                            <span className="badge badge-soft-success">Active</span>
                                          )}
                                          {value.status == 0 && (
                                            <span className="badge badge-soft-warning">
                                              In-Active
                                            </span>
                                          )}
                                        </td>
                                        <td>
                                          <button
                                            type="button"
                                            className="btn btn-default action-icon"
                                            data-tooltip-id="mt-1"
                                            data-tooltip-content="Clone Facility Compliance Lists"
                                            onClick={() => cloneFacilityCompliance(value.id)}
                                          >
                                            <i className="mdi mdi-content-copy" />
                                          </button>
                                          <Tooltip id="mt-1"></Tooltip>

                                          {value.status == 0 && (
                                            <>
                                              <button
                                                type="button"
                                                className="btn btn-default action-icon"
                                                data-tooltip-id="mt-1"
                                                data-tooltip-content="In-Active"
                                                onClick={() => updateStatus(value.id, 1)}
                                              >
                                                <i className="mdi mdi-close-box-outline" />
                                              </button>
                                              <Tooltip id="mt-1"></Tooltip>
                                            </>
                                          )}
                                          {value.status == 1 && (
                                            <>
                                              <button
                                                type="button"
                                                className="btn btn-default action-icon"
                                                data-tooltip-id="mt-1"
                                                data-tooltip-content="Active"
                                                onClick={() => updateStatus(value.id, 0)}
                                              >
                                                <i className="mdi mdi-check-box-outline" />
                                              </button>
                                              <Tooltip id="mt-1"></Tooltip>
                                            </>
                                          )}

                                          <button
                                            type="button"
                                            className="btn btn-default action-icon"
                                            data-tooltip-id="mt-3"
                                            data-tooltip-content="Edit Facility Compliance"
                                            onClick={() => handleEdit(value)}
                                          >
                                            <i className="mdi mdi-pencil" />
                                          </button>
                                          <Tooltip id="mt-3"></Tooltip>

                                          <button
                                            type="button"
                                            className="btn btn-default action-icon"
                                            data-tooltip-id="mt-2"
                                            data-tooltip-content="Remove Facility Compliance"
                                            onClick={() => handleDelete(value)}
                                          >
                                            <i className="mdi mdi-delete" />
                                          </button>
                                          <Tooltip id="mt-2"></Tooltip>
                                        </td>
                                      </tr>
                                    ))}
                                  </>
                                )}
                              </>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/* end card-body*/}
                  </div>
                  {/* end card*/}
                </div>
                {/* end col */}
              </div>
              {/* end row */}
            </div>
            {/* container */}
          </div>

          <Footer />
        </div>
      </div>

      <Modal show={uploadModalOpen} onHide={toggleUploadModal} centered backdrop="static" size="md">
        <form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title className="my-0">Add Facility Compliance</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12 mb-2">
                <label className="form-label" htmlFor="title">
                  Title
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="title"
                  name="title"
                  onChange={handleChange}
                  defaultValue={formData.title !== null ? formData.title : ""}
                />
                {errors.title && <span className="error">{errors.title}</span>}
              </div>
              <div className="col-md-12 mb-2">
                <label className="form-label" htmlFor="type">
                  Type
                </label>
                <select
                  className="form-control"
                  id="type"
                  name="type"
                  onChange={handleChange}
                  value={formData.type && formData.type}
                >
                  <option value="" key="">
                    Please Select
                  </option>

                  {complianceTypes.map((type, index) => (
                    <option key={index} value={type}>
                      {type}
                    </option>
                  ))}
                </select>
                {errors.type && <span className="error">{errors.type}</span>}
              </div>

              <div className="col-md-12 mb-2">
                <label className="form-label" htmlFor="fstatus">
                  Status
                </label>
                <select
                  className="form-control"
                  id="fstatus"
                  name="status"
                  onChange={handleChange}
                  value={formData.status !== null ? formData.status : "1"}
                >
                  <option key="1" value="1">
                    Active
                  </option>
                  <option key="0" value="0">
                    In-Active
                  </option>
                </select>
                {errors.cat_id && <span className="error">{errors.cat_id}</span>}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn btn-secondary" onClick={toggleUploadModal}>
              Close
            </button>
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </Modal.Footer>
        </form>
      </Modal>

      <ThemeSettings />
      {isLoading === true && <Spinner />}
    </>
  );
}

export default ClientFacilityCompliances;

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import Sidebar from "../../Layout/Sidebar";
import ThemeSettings from "../../Layout/ThemeSettings";
import * as api from "../../../services/ApiService";
import * as Yup from "yup";
import * as tokenUtils from "../../../utils/tokenUtils";
import * as toast from "../../../utils/toastUtils";
import Modal from "react-bootstrap/Modal";
import { Tooltip } from "react-tooltip";
import useConfirm from "../../../hooks/useConfirm";

function AddCandidateStep3() {
  let navigate = useNavigate();

  const [modalOpen, setModalOpen] = useState(false);

  // Get data from token util
  let user = tokenUtils.getTokenData();
  let userID = localStorage.getItem("candidate_id");

  const toggleModal = () => {
    setModalOpen(!modalOpen);

    if (!modalOpen) {
      setformData({
        id: null,
        user_id: userID,
        token: user.token,
        title: null,
        company_name: null,
        start_month: null,
        start_year: null,
        end_month: null,
        end_year: null,
        currently_working: null,
        city_id: null,
        state_id: null,
        profession_id: null,
        specialty_id: null,
        employment_type_id: null,
      });

      // Reset validation errors
      setErrors({});
    }
  };

  const [formData, setformData] = useState({
    id: null,
    user_id: userID,
    token: user.token,
    title: null,
    company_name: null,
    start_month: null,
    start_year: null,
    end_month: null,
    end_year: null,
    currently_working: null,
    city_id: null,
    state_id: null,
    profession_id: null,
    specialty_id: null,
    employment_type_id: null,
  });

  const handleEdit = (record) => {
    // Populate formData with the values of the selected record
    setformData({
      user_id: userID,
      token: user.token,
      id: record.id,
      title: record.title,
      company_name: record.company_name,
      start_month: record.start_month,
      start_year: record.start_year,
      end_month: record.end_month,
      end_year: record.end_year,
      currently_working: record.currently_working,
      city_id: record.city_id,
      state_id: record.state_id,
      profession_id: record.profession_id,
      specialty_id: record.specialty_id,
      employment_type_id: record.employment_type_id,
    });

    setModalOpen(true);
  };

  const { confirm } = useConfirm();
  const handleDelete = async (record) => {
    const confirmed = await confirm();
    if (confirmed.isConfirmed) {
      record.user_id = userID;
      record.token = user.token;

      var result = await api.postMethod(record, "user/delete-work-history");
      if (result.status) {
        fetchWorkHistoryData();
      }
    }
  };

  const [cities, setCities] = useState([]);

  useEffect(() => {
    // Function to fetch data from the API
    const fetchCityData = async () => {
      try {
        const apiData = await api.getMethod("get-cities/" + formData.state_id);

        setCities(apiData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchCityData();
  }, [formData.state_id]); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const [specialties, setspecialties] = useState([]);

  useEffect(() => {
    // Function to fetch data from the API
    const fetchSpecialtyData = async () => {
      try {
        const apiData = await api.getMethod("get-specialties/" + formData.profession_id);

        setspecialties(apiData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchSpecialtyData();
  }, [formData.profession_id]); // Empty dependency array ensures that the effect runs only once, when the component mounts

  //Define the validation schema
  const validationSchema = Yup.object({
    title: Yup.string().required("Job Title is Required"),
    company_name: Yup.string().required("Company Name is Required"),
    start_month: Yup.string().required("Start Month is Required"),
    start_year: Yup.string().required("Start Year is Required"),

    /*
    currently_working: Yup.boolean(),
    end_month: Yup.string().when("currently_working", {
      is: "",
      then: Yup.string().required("End Month is Required"),
    }),
    end_year: Yup.string().when("currently_working", {
      is: "",
      then: Yup.string().required("End Year is Required"),
    }),
    
    end_month: Yup.string().when(["currently_working"], (currently_working, schema) => {
      return currently_working === null || currently_working === undefined
        ? schema.required("End Month is Required")
        : schema;
    }),
    end_year: Yup.string().when(["currently_working"], (currently_working, schema) => {
      return currently_working === null || currently_working === undefined
        ? schema.required("End Year is Required")
        : schema;
    }),
    */
    /*
    city_id: Yup.string().required("City is Required"),
    state_id: Yup.string().required("State is Required"),
    profession_id: Yup.string().required("Profession is Required"),
    specialty_id: Yup.string().required("Specialty is Required"),
    employment_type_id: Yup.string().required("Employment Type is Required"),*/
  });

  const [errors, setErrors] = useState({});

  const [workHistories, setWorkHisstories] = useState({});
  const [loading, setLoading] = useState(true);
  const [noRecordsFound, setNoRecordsFound] = useState(false);

  const fetchWorkHistoryData = async () => {
    try {
      const param = {
        user_id: userID,
        token: user.token,
      };
      const apiData = await api.postMethod(param, "user/get-work-history");
      //const apiDataObj = Object.setPrototypeOf(apiData, Object.prototype);

      /*
      const apiDataObj = apiData.reduce((acc, currentValue, index) => {
        acc[index] = currentValue;
        return acc;
      }, {});
      */

      setWorkHisstories(apiData.data);
      setLoading(false);
      // Check if data array is empty
      if (apiData.data.length === 0) {
        setNoRecordsFound(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchWorkHistoryData();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await validationSchema.validate(formData, { abortEarly: false });
      const result = await api.postMethod(formData, "user/update-work-history");
      if (result.status) {
        setModalOpen(false);
        fetchWorkHistoryData();
      }
    } catch (error) {
      const newErrors = {};
      if (error.inner && Array.isArray(error.inner)) {
        error.inner.forEach((element) => {
          newErrors[element.path] = element.message;
        });
      } else {
        console.error("Unexpected error structure:", error);
      }
      setErrors(newErrors);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setformData({ ...formData, [name]: value });

    validateFields(name, value);
  };

  const validateFields = (name, value) => {
    try {
      validationSchema.validateSyncAt(name, { [name]: value });

      // Clear the error for the current field if validation passes
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    } catch (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error.message,
      }));
    }
  };

  // Generate an array of years
  const years = [];
  const currentYear = new Date().getFullYear();
  for (let year = currentYear; year >= currentYear - 15; year--) {
    years.push(year);
  }

  const [states, setStates] = useState([]);
  const [professions, setProfessions] = useState([]);
  const [employmentTypes, setEmploymentTypes] = useState([]);

  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        const apiData = await api.getMethod("get-states");
        setStates(apiData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchData();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        const apiData = await api.getMethod("get-employment-types");
        setEmploymentTypes(apiData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchData();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        const apiData = await api.getMethod("get-professions");
        setProfessions(apiData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchData();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const handleRedirectSubmit = async (e, navigateTo) => {
    e.preventDefault();
    navigate(navigateTo);
  };
  return (
    <>
      <div id="wrapper">
        <Sidebar />

        <div className="content-page">
          <Header />

          <div className="content">
            {/* Start Content*/}
            <div className="container-fluid">
              {/* start page title */}
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box">
                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          <Link to="/client/dashboard">Home</Link>
                        </li>
                        <li className="breadcrumb-item">
                          <Link to="/client/candidates">Candidates</Link>
                        </li>
                        <li className="breadcrumb-item active">Add Candidate</li>
                      </ol>
                    </div>
                    <h4 className="page-title">Add Candidate</h4>
                  </div>
                </div>
              </div>
              {/*Job progress bar start*/}
              <div className="row">
                <div className="col-12">
                  <div className="stepper-wrapper">
                    <div className="stepper-item completed">
                      <div className="step-counter">1</div>
                      <div className="step-name">Personal Information</div>
                    </div>
                    <div className="stepper-item completed">
                      <div className="step-counter">2</div>
                      <div className="step-name">Job Preferences</div>
                    </div>
                    {/* 
                    <div className="stepper-item">
                      <div className="step-counter">3</div>
                      <div className="step-name">Professional Details</div>
                    </div>
                    */}
                    <div className="stepper-item active">
                      <div className="step-counter">3</div>
                      <div className="step-name">Work History</div>
                    </div>
                    <div className="stepper-item">
                      <div className="step-counter">4</div>
                      <div className="step-name">Educational Information</div>
                    </div>
                    <div className="stepper-item">
                      <div className="step-counter">5</div>
                      <div className="step-name">Skills</div>
                    </div>
                  </div>
                </div>
              </div>
              {/*Job progress bar end*/}
              <div className="row">
                <div className="col-12">
                  <div className="card custom-box-shadow">
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table table-centered table-nowrap mb-0">
                          <thead className="table-light">
                            <tr>
                              <th>Company/Organization</th>
                              <th align="center" className="text-center">
                                Start/End Date
                              </th>
                              <th align="center" className="text-center">
                                Profession/Specialty
                              </th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {loading ? (
                              <tr>
                                <td colSpan="4">Loading...</td>
                              </tr>
                            ) : (
                              <>
                                {noRecordsFound ? (
                                  <tr>
                                    <td colSpan="4">No records found</td>
                                  </tr>
                                ) : (
                                  <>
                                    {Object.entries(workHistories).map(([key, value]) => (
                                      <tr key={value.id}>
                                        <td>
                                          {value.title}
                                          {value.company_name !== "null"
                                            ? " - " + value.company_name
                                            : ""}
                                          <p className="mb-0 text-muted">
                                            <small>
                                              {value.state_code !== null && value.city_name !== null
                                                ? value.city_name + ", " + value.state_code
                                                : value.state_code !== null
                                                ? value.state_code
                                                : value.city_name !== null
                                                ? value.city_name
                                                : ""}
                                            </small>
                                          </p>
                                        </td>
                                        <td align="center">
                                          {value.start_month}/{value.start_year} -{" "}
                                          {value.currently_working === "1"
                                            ? "Present"
                                            : value.end_month + "/" + value.end_year}
                                        </td>
                                        <td align="center">
                                          {value.profession !== null && value.specialty !== null
                                            ? value.profession + "/" + value.specialty
                                            : value.profession !== null
                                            ? value.profession
                                            : value.specialty !== null
                                            ? value.profession
                                            : "-"}
                                        </td>
                                        <td>
                                          <span
                                            className="action-icon"
                                            onClick={() => handleEdit(value)}
                                          >
                                            <i className="mdi mdi-pencil" />
                                          </span>
                                          <span
                                            className="action-icon"
                                            onClick={() => handleDelete(value)}
                                          >
                                            <i className="mdi mdi-delete" />
                                          </span>
                                        </td>
                                      </tr>
                                    ))}
                                  </>
                                )}
                              </>
                            )}
                          </tbody>
                        </table>
                      </div>

                      <Link
                        to={`/client/add-candidate-step2`}
                        className="btn btn-secondary mt-2 me-1 float-start"
                        type="submit"
                      >
                        <i className="fas fa-angle-left" /> Go Back
                      </Link>

                      <button
                        type="submit"
                        className="btn btn-info float-start mt-2"
                        onClick={toggleModal}
                      >
                        <i className="fa fa-plus"></i> Add Work History
                      </button>

                      <button
                        className="btn btn-info ms-1 float-end mt-2 ms-1"
                        type="button"
                        onClick={(e) => handleRedirectSubmit(e, "/client/candidates")}
                      >
                        Save & Exit <i className="fas fa-check" />
                      </button>

                      <Link
                        to="/client/add-candidate-step4"
                        className="btn btn-primary mt-2 float-end"
                      >
                        Save and Continue <i className="fas fa-angle-right" />
                      </Link>

                      <Modal
                        show={modalOpen}
                        onHide={toggleModal}
                        centered
                        backdrop="static"
                        size="lg"
                      >
                        <form onSubmit={handleSubmit}>
                          <Modal.Header closeButton>
                            <Modal.Title className="my-0">Add Work History</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="mb-2">
                                  <label htmlFor="title" className="form-label">
                                    Job Title
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="title"
                                    name="title"
                                    onChange={handleChange}
                                    defaultValue={formData.title}
                                  />
                                  {errors.title && <span className="error">{errors.title}</span>}
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="mb-2">
                                  <label htmlFor="company_name" className="form-label">
                                    Company/Organization
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="company_name"
                                    name="company_name"
                                    onChange={handleChange}
                                    defaultValue={formData.company_name}
                                  />
                                  {errors.company_name && (
                                    <span className="error">{errors.company_name}</span>
                                  )}
                                </div>
                              </div>

                              <div className="col-md-3">
                                <div className="mb-2">
                                  <label htmlFor="start_month" className="form-label">
                                    Start Month
                                  </label>
                                  <select
                                    className="form-control"
                                    id="start_month"
                                    name="start_month"
                                    onChange={handleChange}
                                    value={
                                      formData.start_month !== null ? formData.start_month : ""
                                    }
                                  >
                                    <option value="" key="">
                                      Please Select
                                    </option>
                                    {Array.from(Array(12), (e, i) => {
                                      return (
                                        <option value={i + 1} key={i + 1}>
                                          {i + 1}
                                        </option>
                                      );
                                    })}
                                  </select>
                                  {errors.start_month && (
                                    <span className="error">{errors.start_month}</span>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="mb-2">
                                  <label htmlFor="start_year" className="form-label">
                                    Start Year
                                  </label>
                                  <select
                                    className="form-control"
                                    id="start_year"
                                    name="start_year"
                                    onChange={handleChange}
                                    value={formData.start_year !== null ? formData.start_year : ""}
                                  >
                                    <option value="" key="">
                                      Please Select
                                    </option>
                                    {years.map((year) => (
                                      <option key={year} value={year}>
                                        {year}
                                      </option>
                                    ))}
                                  </select>
                                  {errors.start_year && (
                                    <span className="error">{errors.start_year}</span>
                                  )}
                                </div>
                              </div>

                              <div className="col-md-3">
                                <div className="mb-2">
                                  <label htmlFor="end_month" className="form-label">
                                    End Month
                                  </label>
                                  <select
                                    className="form-control"
                                    id="end_month"
                                    name="end_month"
                                    onChange={handleChange}
                                    value={formData.end_month !== null ? formData.end_month : ""}
                                  >
                                    <option value="" key="">
                                      Please Select
                                    </option>
                                    {Array.from(Array(12), (e, i) => {
                                      return (
                                        <option value={i + 1} key={i + 1}>
                                          {i + 1}
                                        </option>
                                      );
                                    })}
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="mb-2">
                                  <label htmlFor="end_year" className="form-label">
                                    End Year
                                  </label>
                                  <label className="float-end">
                                    <input
                                      type="checkbox"
                                      name="currently_working"
                                      value="1"
                                      data-tooltip-id="tt-1"
                                      data-tooltip-content="Currently Working"
                                      onChange={handleChange}
                                      defaultChecked={formData.currently_working}
                                    />
                                    <Tooltip id="tt-1"></Tooltip>
                                  </label>
                                  <select
                                    className="form-control"
                                    id="end_year"
                                    name="end_year"
                                    onChange={handleChange}
                                    value={formData.end_year !== null ? formData.end_year : ""}
                                  >
                                    <option value="" key="">
                                      Please Select
                                    </option>
                                    {years.map((year) => (
                                      <option key={year} value={year}>
                                        {year}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-3">
                                <div className="mb-2">
                                  <label htmlFor="state_id" className="form-label">
                                    State
                                  </label>

                                  <select
                                    className="form-control"
                                    name="state_id"
                                    onChange={handleChange}
                                    value={formData.state_id !== null ? formData.state_id : ""}
                                  >
                                    <option value="" key="">
                                      Please Select
                                    </option>
                                    {/* Render the states */}
                                    {states.map((state) => (
                                      <option key={state.id} value={state.id}>
                                        {state.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="mb-2">
                                  <label htmlFor="city_id" className="form-label">
                                    City
                                  </label>
                                  <select
                                    className="form-control"
                                    name="city_id"
                                    onChange={handleChange}
                                    value={formData.city_id !== null ? formData.city_id : ""}
                                  >
                                    <option value="" key="">
                                      Please Select
                                    </option>
                                    {/* Render the cities */}
                                    {cities.map((city) => (
                                      <option key={city.id} value={city.id}>
                                        {city.city_name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>

                              <div className="col-md-3">
                                <div className="mb-0">
                                  <label htmlFor="profession_id" className="form-label">
                                    Profession
                                  </label>

                                  <select
                                    className="form-control"
                                    id="profession_id"
                                    name="profession_id"
                                    onChange={handleChange}
                                    value={
                                      formData.profession_id !== null ? formData.profession_id : ""
                                    }
                                  >
                                    <option value="" key="">
                                      Please Select
                                    </option>
                                    {/* Render the professions */}
                                    {professions.map((profession) => (
                                      <option key={profession.id} value={profession.id}>
                                        {profession.profession}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="mb-0">
                                  <label htmlFor="specialty_id" className="form-label">
                                    Specialty
                                  </label>
                                  <select
                                    className="form-control"
                                    id="specialty_id"
                                    name="specialty_id"
                                    onChange={handleChange}
                                    value={
                                      formData.specialty_id !== null ? formData.specialty_id : ""
                                    }
                                  >
                                    <option value="" key="">
                                      Please Select
                                    </option>

                                    {/* Render the specialties */}
                                    {specialties.map((specialty) => (
                                      <option key={specialty.id} value={specialty.id}>
                                        {specialty.specialty}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="mb-0">
                                  <label htmlFor="employment_type_id" className="form-label">
                                    Employment Type
                                  </label>
                                  <select
                                    className="form-control"
                                    id="employment_type_id"
                                    name="employment_type_id"
                                    onChange={handleChange}
                                    value={
                                      formData.employment_type_id !== null
                                        ? formData.employment_type_id
                                        : ""
                                    }
                                  >
                                    <option value="" key="">
                                      Please Select
                                    </option>

                                    {/* Render the employment types */}
                                    {employmentTypes.map((etype) => (
                                      <option key={etype.id} value={etype.id}>
                                        {etype.title}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              </div>
                            </div>
                          </Modal.Body>
                          <Modal.Footer>
                            <button
                              type="button"
                              className="btn btn-secondary"
                              onClick={toggleModal}
                            >
                              Close
                            </button>
                            <button type="submit" className="btn btn-primary">
                              Save
                            </button>
                          </Modal.Footer>
                        </form>
                      </Modal>
                    </div>
                    {/* end card-body*/}
                  </div>
                  {/* end card*/}
                </div>
                {/* end col */}
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
      <ThemeSettings />
    </>
  );
}

export default AddCandidateStep3;

import React, { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import Layout from "../../../Layout/Index";
import * as api from "../../../../services/ApiService";
import * as tokenUtils from "../../../../utils/tokenUtils";
import { formatDate } from "../../../../utils/dateFormat";
import PageTitle from "../../../Layout/PageTitle";
import { Tooltip } from "react-tooltip";
import { toast } from "react-toastify";
import Spinner from "../../../Loader/Spinner";
import useConfirm from "../../../../hooks/useConfirm";

const SchedulingListings = forwardRef((props, ref) => {
  // Get data from token util
  let user = tokenUtils.getTokenData();

  useImperativeHandle(ref, () => ({
    scheduleChildFunction() {
      getSchedules();
    },
  }));

  /* Filters function Start */
  const [showFilterDropdown, setFilterDropdown] = useState(false);
  const handleToggleFilterDropDown = () => {
    setFilterDropdown(!showFilterDropdown);
  };

  // State for filter values
  const [filters, setFilters] = useState({
    keyword: "",
    start_date: "",
    end_date: "",
    status: "all",
  });

  // Handle form reset
  const handleReset = () => {
    const defaultFilters = {
      keyword: "",
      start_date: "",
      end_date: "",
      status: "all",
    };
    setFilters(defaultFilters);
    setFilterDropdown(false);
  };

  useEffect(() => {
    if (showFilterDropdown === false) {
      getSchedules();
    }
  }, [showFilterDropdown]);

  // Handle form input change
  const handleFilterInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setFilters({
        ...filters,
        [name]: checked,
      });
    } else {
      setFilters({
        ...filters,
        [name]: value,
      });
    }
  };

  // Handle form submit
  const handleFilterSubmit = (e) => {
    e.preventDefault();
    setFilterDropdown(false);
  };
  /* Filters function End */

  const [isLoading, setIsLoading] = useState(false);
  const [complianceFiles, setComplianceFiles] = useState({});
  const [loading, setLoading] = useState(true);
  const [noRecordsFound, setNoRecordsFound] = useState(false);

  const getSchedules = async (tab) => {
    try {
      const param = {
        user_id: user.id,
        token: user.token,
        tab: tab,
      };
      const queryParams = { ...param, ...filters };
      const apiData = await api.postMethod(queryParams, "admin/get-schedules");

      setComplianceFiles(apiData.data);
      setLoading(false);
      // Check if data array is empty
      if (apiData.data.length === 0) {
        setNoRecordsFound(true);
      } else setNoRecordsFound(false);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getSchedules();
  }, []);

  const { confirm } = useConfirm();
  const handleDelete = async (record) => {
    const confirmed = await confirm();
    if (confirmed.isConfirmed) {
      setIsLoading(true);
      record.user_id = user.id;
      const result = await api.postMethod(record, "admin/delete-schedule");
      setIsLoading(false);
      if (result.status) {
        toast.success(result.message);
        getSchedules();
      }
    }
  };

  const updateStatus = async (id, status) => {
    try {
      setIsLoading(true);
      const param = {
        user_id: user.id,
        id: id,
        status: status,
      };
      const result = await api.postFileMethod(param, "admin/update-schedule-status");
      setIsLoading(false);
      if (result.status) {
        toast.success(result.message);
        getSchedules();
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="mb-2">
        <div className="row justify-content-between mb-2">
          <div className="col-auto">
            <div className="dropdown" style={{ width: 300 }}>
              <button
                type="button"
                className="btn btn-outline-light dropdown-toggle w-100 text-start"
                onClick={handleToggleFilterDropDown}
              >
                Filters
                <i className="mdi mdi-chevron-down float-end" />
              </button>
              <div className={`dropdown-menu ${showFilterDropdown ? "show" : ""}`}>
                <form className="px-2 py-2" onSubmit={handleFilterSubmit}>
                  <div className="mb-2">
                    <label htmlFor="keyword" className="form-label">
                      Keyword
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="keyword"
                      name="keyword"
                      value={filters.keyword}
                      onChange={handleFilterInputChange}
                    />
                  </div>

                  <div className="mb-2">
                    <label htmlFor="start_date" className="form-label">
                      Date Range
                    </label>
                    <div className="input-group">
                      <input
                        type="date"
                        name="start_date"
                        className="form-control"
                        value={filters.start_date}
                        onChange={handleFilterInputChange}
                      />
                      <span className="input-group-text">To</span>
                      <input
                        type="date"
                        name="end_date"
                        className="form-control"
                        value={filters.end_date}
                        onChange={handleFilterInputChange}
                      />
                    </div>
                  </div>

                  <div className="mb-2">
                    <label htmlFor="status" className="form-label">
                      Status
                    </label>
                    <select
                      className="form-select"
                      id="cstatus"
                      name="status"
                      value={filters.status}
                      onChange={handleFilterInputChange}
                    >
                      <option value={"all"}>Please Select</option>
                      <option value={1}>Active</option>
                      <option value={0}>In-Active</option>
                    </select>
                  </div>

                  <div className="text-end mt-1">
                    <button
                      type="button"
                      className="btn btn-light float-start"
                      onClick={handleReset}
                    >
                      Reset Filter
                    </button>
                    <button type="submit" className="btn btn-primary">
                      Apply Filter
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-centered table-nowrap mb-0">
          <thead className="table-light">
            <tr>
              <th>Title</th>
              <th>Schedule Date</th>
              <th>Assigned To</th>
              <th>Scheduled By</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan="6">Loading...</td>
              </tr>
            ) : (
              <>
                {noRecordsFound ? (
                  <tr>
                    <td colSpan="6">No records found</td>
                  </tr>
                ) : (
                  <>
                    {Object.entries(complianceFiles).map(([key, value]) => (
                      <tr key={value.id}>
                        <td>{value.title}</td>
                        <td>
                          {formatDate(value.start_date + " " + value.start_time, "MMM DD, YYYY")}{" "}
                          <small className="text-muted">
                            {formatDate(value.start_date + " " + value.start_time, "hh:mm A")}
                          </small>{" "}
                          - {formatDate(value.end_date + " " + value.end_time, "MMM DD, YYYY")}{" "}
                          <small className="text-muted">
                            {formatDate(value.end_date + " " + value.end_time, "hh:mm A")}
                          </small>
                        </td>
                        <td>
                          <Link to={`/admin/user-profile/` + value.user_unique_id} className="">
                            {value.name}
                          </Link>
                        </td>
                        <td>
                          {(value.creator_role_id == 2 && (
                            <Link
                              to={`/admin/facility-profile/` + value.creator_unique_id}
                              className=""
                            >
                              {value.creator_name}
                            </Link>
                          )) ||
                            (value.creator_role_id == 3 && (
                              <Link
                                to={`/admin/agency-profile/` + value.creator_unique_id}
                                className=""
                              >
                                {value.creator_name}
                              </Link>
                            )) || <span className="">{value.creator_name}</span>}
                        </td>
                        <td>
                          {value.status == 1 && (
                            <label className="badge badge-soft-success">Active</label>
                          )}
                          {value.status == 0 && (
                            <label className="badge badge-soft-warning">In-Active</label>
                          )}
                        </td>
                        <td>
                          {value.status == 0 && (
                            <>
                              <button
                                type="button"
                                className="btn btn-default action-icon"
                                data-tooltip-id="mt-1"
                                data-tooltip-content="In-Active"
                                onClick={() => updateStatus(value.id, 1)}
                              >
                                <i className="mdi mdi-close-box-outline" />
                              </button>
                              <Tooltip id="mt-1"></Tooltip>
                            </>
                          )}
                          {value.status == 1 && (
                            <>
                              <button
                                type="button"
                                className="btn btn-default action-icon"
                                data-tooltip-id="mt-1"
                                data-tooltip-content="Active"
                                onClick={() => updateStatus(value.id, 0)}
                              >
                                <i className="mdi mdi-check-box-outline" />
                              </button>
                              <Tooltip id="mt-1"></Tooltip>
                            </>
                          )}

                          <button
                            type="button"
                            className="btn btn-default action-icon"
                            data-tooltip-id="mt-3"
                            data-tooltip-content="Edit Schedule"
                            onClick={() => props.parentHandleEdit(value)}
                          >
                            <i className="mdi mdi-pencil" />
                          </button>
                          <Tooltip id="mt-3"></Tooltip>

                          <button
                            type="button"
                            className="btn btn-default action-icon"
                            data-tooltip-id="mt-2"
                            data-tooltip-content="Remove Schedule"
                            onClick={() => handleDelete(value)}
                          >
                            <i className="mdi mdi-delete" />
                          </button>
                          <Tooltip id="mt-2"></Tooltip>
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </>
            )}
          </tbody>
        </table>
      </div>

      {isLoading === true && <Spinner />}
    </>
  );
});

export default SchedulingListings;

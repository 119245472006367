import React, { useEffect, useState } from "react";
import Layout from "../../Layout/Index";
import PageTitle from "../../Layout/PageTitle";
import { getTokenData } from "../../../utils/tokenUtils";
import * as api from "../../../services/ApiService";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { toast } from "react-toastify";
import Spinner from "../../Loader/Spinner";

const breadcrumbItems = [
  { text: "Admin Dashboard", link: "/admin/dashboard" },
  { text: "Announcements", link: null },
];

const Announcements = () => {
  const [isLoading, setIsLoading] = useState(false);
  const user = getTokenData();
  const [roles, setRoles] = useState([]);
  const [announcements, setAnnouncements] = useState([]);
  const [descriptions, setDescriptions] = useState({});

  const fetchUserRoles = async () => {
    try {
      const param = { user_id: user.id };
      const apiData = await api.postMethod(param, "admin/get-user-roles");
      setRoles(apiData.data);
    } catch (error) {
      console.error("Error fetching roles:", error);
    }
  };

  const fetchAnnouncements = async () => {
    try {
      const param = { user_id: user.id };
      const apiData = await api.postMethod(param, "admin/get-announcements");
      setAnnouncements(apiData.data);
      const initialDescriptions = {};
      apiData.data.forEach((item) => {
        initialDescriptions[item.role_id] = item.description;
      });
      setDescriptions(initialDescriptions);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching announcements:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchUserRoles();
    fetchAnnouncements();
  }, []);

  const handleEditorChange = (roleId, data) => {
    setDescriptions((prevState) => ({
      ...prevState,
      [roleId]: data,
    }));
  };

  const handleSubmit = async (event) => {
    setIsLoading(true);
    event.preventDefault();
    const announcementData = roles.map((role) => {
      const existingAnnouncement = announcements.find(
        (announcement) => announcement.role_id === role.id
      );
      return {
        role_id: role.id,
        description: descriptions[role.id] || "",
        // ...(existingAnnouncement ? { id: existingAnnouncement.id } : {})
      };
    });

    try {
      const param = {
        user_id: user.id,
        announcement: announcementData,
      };
      const result = await api.postMethod(param, "admin/add-edit-announcement");
      toast.success(result.message);
      fetchAnnouncements();
    } catch (error) {
      console.error("Error submitting announcements:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Layout>
      <PageTitle title="Announcements" breadcrumbItems={breadcrumbItems} />
      <div className="row">
        <div className="col-12">
          <div className="card custom-box-shadow">
            <div className="card-body">
              <form onSubmit={handleSubmit} noValidate>
                {roles.map((role) => (
                  <div className="form-group mb-3 col-md-12" key={role.id}>
                    <label htmlFor={`role-${role.id}`} className="form-label">
                      {role.role}'s Announcement
                    </label>
                    <CKEditor
                      editor={ClassicEditor}
                      data={descriptions[role.id] || ""}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        handleEditorChange(role.id, data);
                      }}
                    />
                  </div>
                ))}
                <div className="row">
                  <div className="col-md-12">
                    <div className="text-lg-end">
                      <button type="submit" className="btn btn-primary me-1">
                        Submit
                      </button>
                      <button className="btn btn-info" type="reset">
                        Reset
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {isLoading && <Spinner />}
    </Layout>
  );
};

export default Announcements;

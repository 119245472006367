import React, { useState, useEffect } from "react";
import Layout from "../../Layout/Index";
import { Link } from "react-router-dom";
import * as api from "../../../services/ApiService";
import { Tooltip } from "react-tooltip";
import * as tokenUtils from "../../../utils/tokenUtils";
import * as Yup from "yup";
import Spinner from "../../Loader/Spinner";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import { formatDate } from "../../../utils/dateFormat";
import PageTitle from "../../Layout/PageTitle";
import useConfirm from "../../../hooks/useConfirm";

const Assignments = () => {
  // Get data from token util
  let user = tokenUtils.getTokenData();

  /* Filters function Start */
  const [showFilterDropdown, setFilterDropdown] = useState(false);
  const handleToggleFilterDropDown = () => {
    setFilterDropdown(!showFilterDropdown);
  };

  // State for filter values
  const [filters, setFilters] = useState({
    keyword: "",
    status: "all",
  });

  // Handle form reset
  const handleReset = () => {
    const defaultFilters = {
      keyword: "",
      status: "all",
    };
    setFilters(defaultFilters);
    setFilterDropdown(false);
  };

  useEffect(() => {
    if (showFilterDropdown === false) {
      fetchComplianceFiles();
    }
  }, [showFilterDropdown]);

  // Handle form input change
  const handleFilterInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setFilters({
        ...filters,
        [name]: checked,
      });
    } else {
      setFilters({
        ...filters,
        [name]: value,
      });
    }
  };

  // Handle form submit
  const handleFilterSubmit = (e) => {
    e.preventDefault();
    setFilterDropdown(false);
  };
  /* Filters function End */

  /*
  const [UserLists, setUserLists] = useState([]);
  const fetchUserLists = async () => {
    try {
      const apiData = await api.getMethod("get-users/4");

      setUserLists(apiData.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserLists();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts
  */

  //const [applicantLists, setapplicantLists] = useState([]);
  const [candidateLists, setcandidateLists] = useState([]);
  const [employeeLists, setemployeeLists] = useState([]);
  //const [jobapplicantLists, setjobapplicantLists] = useState([]);
  const fetchUserLists = async () => {
    try {
      const param = {
        user_id: user.id,
        token: user.token,
      };
      const apiData = await api.postMethod(param, "admin/get-checklist-assigned-users");

      //setapplicantLists(apiData.data.applicants);
      setcandidateLists(apiData.data.candidates);
      setemployeeLists(apiData.data.employees);
      //setjobapplicantLists(apiData.data.job_applicants);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserLists();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const [facilityLists, setFacilityLists] = useState([]);
  const fetchFacilities = async () => {
    try {
      const param = {
        user_id: user.id,
        token: user.token,
      };
      const apiData = await api.postMethod(param, "admin/get-clients");

      setFacilityLists(apiData.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchFacilities();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const [isLoading, setIsLoading] = useState(false);
  const [complianceFiles, setComplianceFiles] = useState({});
  const [loading, setLoading] = useState(true);
  const [noRecordsFound, setNoRecordsFound] = useState(false);

  const fetchComplianceFiles = async () => {
    try {
      const param = {
        user_id: user.id,
        token: user.token,
      };
      const queryParams = { ...param, ...filters };
      const apiData = await api.postMethod(queryParams, "admin/get-assignments");

      setComplianceFiles(apiData.data);
      setLoading(false);
      // Check if data array is empty
      if (apiData.data.length === 0) {
        setNoRecordsFound(true);
      } else setNoRecordsFound(false);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchComplianceFiles();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const [formData, setformData] = useState({
    id: null,
    user_id: user.id,
    assigned_user_id: null,
    facility_id: null,
    state_id: null,
    city_id: null,
    assigned_unit: null,
    start_date: null,
    end_date: null,
    assignment_length: null,
    shift: null,
    approved_time_off: null,
    confirmed_with_facility: 0,
    confirmed_with_traveler: 0,
    status: null,
  });

  const [states, setStates] = useState([]);

  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        const apiData = await api.getMethod("get-states");
        setStates(apiData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchData();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const [cities, setCities] = useState([]);

  useEffect(() => {
    // Function to fetch data from the API
    const fetchCityData = async () => {
      try {
        const apiData = await api.getMethod("get-cities/" + formData.state_id);

        setCities(apiData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchCityData();
  }, [formData.state_id]); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const [uploadModalOpen, setUploadModalOpen] = useState(false);

  const toggleUploadModal = () => {
    setUploadModalOpen(!uploadModalOpen);

    if (!uploadModalOpen) {
      setformData({
        id: null,
        user_id: user.id,
        assigned_user_id: null,
        facility_id: null,
        state_id: null,
        city_id: null,
        assigned_unit: null,
        start_date: null,
        end_date: null,
        assignment_length: null,
        shift: null,
        approved_time_off: null,
        confirmed_with_facility: 0,
        confirmed_with_traveler: 0,
        status: null,
      });

      // Reset validation errors
      setErrors({});
    }
  };

  const handleEdit = (record) => {
    // Populate formData with the values of the selected record
    setformData({
      user_id: user.id,
      id: record.id,
      assigned_user_id: record.user_id,
      facility_id: record.facility_id,
      state_id: record.state_id,
      city_id: record.city_id,
      assigned_unit: record.assigned_unit,
      start_date: record.start_date,
      end_date: record.end_date,
      assignment_length: record.assignment_length,
      shift: record.shift,
      approved_time_off: record.approved_time_off,
      confirmed_with_facility: record.confirmed_with_facility,
      confirmed_with_traveler: record.confirmed_with_traveler,
      status: record.status,
    });

    setUploadModalOpen(true);
  };

  const { confirm } = useConfirm();
  const handleDelete = async (record) => {
    const confirmed = confirm();
    if (confirmed.isConfirmed) {
      record.user_id = user.id;
      setIsLoading(true);
      const result = await api.postMethod(record, "admin/delete-assignment");
      setIsLoading(false);
      if (result.status) {
        toast.success(result.message);
        fetchComplianceFiles();
      }
    }
  };

  //Define the validation schema
  const validationSchema = Yup.object({
    assigned_user_id: Yup.string().required("Candidate is Required"),
    facility_id: Yup.string().required("Facility is Required"),
    state_id: Yup.string().required("State is Required"),
    city_id: Yup.string().required("City is Required"),
  });

  const [errors, setErrors] = useState({});
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await validationSchema.validate(formData, { abortEarly: false });

      setIsLoading(true);
      const result = await api.postFileMethod(formData, "admin/update-assignment");
      setIsLoading(false);
      if (result.status) {
        toast.success(result.message);

        //document.getElementById("file_name").value = "";
        setUploadModalOpen(false);
        fetchComplianceFiles();
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      setIsLoading(false);
      const newErrors = {};
      if (error.inner && Array.isArray(error.inner)) {
        error.inner.forEach((element) => {
          newErrors[element.path] = element.message;
        });
      } else {
        console.error("Unexpected error structure:", error);
      }
      setErrors(newErrors);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setformData({ ...formData, [name]: value });

    validateFields(name, value);
  };

  const validateFields = (name, value) => {
    try {
      validationSchema.validateSyncAt(name, { [name]: value });

      // Clear the error for the current field if validation passes
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    } catch (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error.message,
      }));
    }
  };

  const updateStatus = async (id, status) => {
    try {
      setIsLoading(true);
      const param = {
        user_id: user.id,
        id: id,
        status: status,
      };
      const result = await api.postFileMethod(param, "admin/update-assignment-status");
      setIsLoading(false);
      if (result.status) {
        toast.success(result.message);
        fetchComplianceFiles();
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const breadcrumbItems = [
    { text: "Admin Dashboard", link: "/admin/dashboard" },
    { text: "Assignments", link: null },
  ];
  return (
    <Layout>
      <PageTitle title="Assignments" breadcrumbItems={breadcrumbItems} />
      {/* end page title */}
      <div className="row">
        <div className="col-12">
          <div className="card custom-box-shadow">
            <div className="card-body">
              <div className="row justify-content-between">
                <div className="col-auto">
                  <div className="dropdown" style={{ width: 300 }}>
                    <button
                      type="button"
                      className="btn btn-outline-light dropdown-toggle w-100 text-start"
                      onClick={handleToggleFilterDropDown}
                    >
                      Filters
                      <i className="mdi mdi-chevron-down float-end" />
                    </button>
                    <div className={`dropdown-menu ${showFilterDropdown ? "show" : ""}`}>
                      <form className="px-2 py-2" onSubmit={handleFilterSubmit}>
                        <div className="mb-2">
                          <label htmlFor="keyword" className="form-label">
                            Keyword
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="keyword"
                            name="keyword"
                            value={filters.keyword}
                            onChange={handleFilterInputChange}
                          />
                        </div>

                        <div className="mb-2">
                          <label htmlFor="status" className="form-label">
                            Status
                          </label>
                          <select
                            className="form-select"
                            id="cstatus"
                            name="status"
                            value={filters.status}
                            onChange={handleFilterInputChange}
                          >
                            <option value={"all"}>Please Select</option>
                            <option value={1}>Active</option>
                            <option value={0}>In-Active</option>
                          </select>
                        </div>

                        <div className="text-end mt-1">
                          <button
                            type="button"
                            className="btn btn-light float-start"
                            onClick={handleReset}
                          >
                            Reset Filter
                          </button>
                          <button type="submit" className="btn btn-primary">
                            Apply Filter
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="col-auto">
                  <div className="text-lg-end my-1 my-lg-0">
                    <button
                      type="button"
                      className="btn btn-primary waves-effect waves-light mb-2 me-1"
                      onClick={toggleUploadModal}
                    >
                      <i className="fa fa-plus mr-1"></i> Add New
                    </button>
                  </div>
                </div>
                {/* end col*/}
              </div>
              <div className="table-responsive">
                <table className="table table-centered table-nowrap mb-0">
                  <thead className="table-light">
                    <tr>
                      <th>Facility Name</th>
                      <th>Assignee</th>
                      <th>Start/End Date</th>
                      <th>Location</th>
                      <th>Submission Date</th>
                      <th>Submitted By</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan="8">Loading...</td>
                      </tr>
                    ) : (
                      <>
                        {noRecordsFound ? (
                          <tr>
                            <td colSpan="8">No records found</td>
                          </tr>
                        ) : (
                          <>
                            {Object.entries(complianceFiles).map(([key, value]) => (
                              <tr key={value.id}>
                                <td>{value.facility_title || "-"}</td>
                                <td>
                                  <Link
                                    to={`/admin/user-profile/` + value.user_unique_id}
                                    className=""
                                  >
                                    {value.name}
                                  </Link>
                                </td>
                                <td>
                                  {formatDate(value.start_date, "MMM DD, YYYY")} to{" "}
                                  {formatDate(value.end_date, "MMM DD, YYYY")}
                                </td>
                                <td>
                                  {value.state_code !== null && value.city_name !== null
                                    ? value.city_name + ", " + value.state_code
                                    : value.state_code !== null
                                    ? value.state_code
                                    : value.city_name !== null
                                    ? value.city_name
                                    : ""}
                                </td>
                                <td>
                                  {formatDate(value.created_at, "MMM DD, YYYY")}{" "}
                                  <small className="text-muted">
                                    {formatDate(value.created_at, "hh:mm A")}
                                  </small>
                                </td>
                                <td>
                                  {(value.submitted_role_id == 2 && (
                                    <Link
                                      to={`/admin/facility-profile/` + value.submitted_unique_id}
                                      className=""
                                    >
                                      {value.submitted_name}
                                    </Link>
                                  )) ||
                                    (value.submitted_role_id == 3 && (
                                      <Link
                                        to={`/admin/agency-profile/` + value.submitted_unique_id}
                                        className=""
                                      >
                                        {value.submitted_name}
                                      </Link>
                                    )) || <span className="">{value.submitted_name}</span>}
                                </td>
                                <td>
                                  {value.status == 1 && (
                                    <span className="badge badge-soft-success">Active</span>
                                  )}
                                  {value.status == 0 && (
                                    <span className="badge badge-soft-warning">In-Active</span>
                                  )}
                                </td>
                                <td>
                                  {value.status == 0 && (
                                    <>
                                      <button
                                        type="button"
                                        className="btn btn-default action-icon"
                                        data-tooltip-id="mt-1"
                                        data-tooltip-content="In-Active"
                                        onClick={() => updateStatus(value.id, 1)}
                                      >
                                        <i className="mdi mdi-close-box-outline" />
                                      </button>
                                      <Tooltip id="mt-1"></Tooltip>
                                    </>
                                  )}
                                  {value.status == 1 && (
                                    <>
                                      <button
                                        type="button"
                                        className="btn btn-default action-icon"
                                        data-tooltip-id="mt-1"
                                        data-tooltip-content="Active"
                                        onClick={() => updateStatus(value.id, 0)}
                                      >
                                        <i className="mdi mdi-check-box-outline" />
                                      </button>
                                      <Tooltip id="mt-1"></Tooltip>
                                    </>
                                  )}

                                  <button
                                    type="button"
                                    className="btn btn-default action-icon"
                                    data-tooltip-id="mt-3"
                                    data-tooltip-content="Edit Assignment"
                                    onClick={() => handleEdit(value)}
                                  >
                                    <i className="mdi mdi-pencil" />
                                  </button>
                                  <Tooltip id="mt-3"></Tooltip>
                                  <button
                                    type="button"
                                    className="btn btn-default action-icon"
                                    data-tooltip-id="mt-2"
                                    data-tooltip-content="Remove Assignment"
                                    onClick={() => handleDelete(value)}
                                  >
                                    <i className="mdi mdi-delete" />
                                  </button>
                                  <Tooltip id="mt-2"></Tooltip>
                                </td>
                              </tr>
                            ))}
                          </>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            {/* end card-body*/}
          </div>
          {/* end card*/}
        </div>
        {/* end col */}
      </div>

      <Modal show={uploadModalOpen} onHide={toggleUploadModal} centered backdrop="static" size="lg">
        <form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title className="my-0">Add Assignment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="mb-2 col-md-4">
                <label className="form-label" htmlFor="assigned_user_id">
                  Assignee *
                </label>
                <select
                  className="form-control"
                  id="assigned_user_id"
                  name="assigned_user_id"
                  onChange={handleChange}
                  value={formData.assigned_user_id !== null ? formData.assigned_user_id : ""}
                >
                  <option value="" key="">
                    Please Select
                  </option>
                  {/* UserLists.map((user) => (
                    <option key={user.id} value={user.id}>
                      {user.name}
                    </option>
                  )) */}
                  {candidateLists.length > 0 && (
                    <optgroup label="Candidates">
                      {candidateLists &&
                        candidateLists.map((user) => (
                          <option key={user.id} value={user.id}>
                            {user.name}
                          </option>
                        ))}
                      candidateLists && (
                    </optgroup>
                  )}

                  {employeeLists.length > 0 && (
                    <optgroup label="Employees">
                      {employeeLists &&
                        employeeLists.map((user) => (
                          <option key={user.id} value={user.id}>
                            {user.name}
                          </option>
                        ))}
                      employeeLists && (
                    </optgroup>
                  )}
                </select>
                {errors.assigned_user_id && (
                  <span className="error">{errors.assigned_user_id}</span>
                )}
              </div>

              <div className="mb-2 col-md-4">
                <label className="form-label" htmlFor="facility_id">
                  Facility List *
                </label>

                <input
                  type="text"
                  className="form-control"
                  id="facility_id"
                  name="facility_id"
                  onChange={handleChange}
                  defaultValue={formData.facility_id !== null ? formData.facility_id : ""}
                />
                {/*
                <select
                  className="form-control"
                  id="facility_id"
                  name="facility_id"
                  onChange={handleChange}
                  value={formData.facility_id !== null ? formData.facility_id : ""}
                >
                  <option value="" key="">
                    Please Select
                  </option>
                  {facilityLists.map((facility) => (
                    <option key={facility.id} value={facility.id}>
                      {facility.title}
                    </option>
                  ))}
                </select>
                 */}
                {errors.facility_id && <span className="error">{errors.facility_id}</span>}
              </div>

              <div className="col-md-4 mb-2">
                <label className="form-label" htmlFor="assigned_unit">
                  Assigned Unit
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="assigned_unit"
                  name="assigned_unit"
                  onChange={handleChange}
                  defaultValue={formData.assigned_unit !== null ? formData.assigned_unit : ""}
                />
              </div>

              <div className="col-md-4 mb-2">
                <label className="form-label" htmlFor="start_date">
                  Start Date
                </label>
                <input
                  type="date"
                  className="form-control"
                  id="start_date"
                  name="start_date"
                  onChange={handleChange}
                  defaultValue={formData.start_date !== null ? formData.start_date : ""}
                />
              </div>

              <div className="col-md-4 mb-2">
                <label className="form-label" htmlFor="end_date">
                  End Date
                </label>
                <input
                  type="date"
                  className="form-control"
                  id="end_date"
                  name="end_date"
                  onChange={handleChange}
                  defaultValue={formData.end_date !== null ? formData.end_date : ""}
                />
              </div>

              <div className="col-md-4 mb-2">
                <label className="form-label" htmlFor="assignment_length">
                  Assignment Length
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="assignment_length"
                  name="assignment_length"
                  onChange={handleChange}
                  defaultValue={
                    formData.assignment_length !== null ? formData.assignment_length : ""
                  }
                />
              </div>

              <div className="mb-2 col-md-4">
                <label htmlFor="state_id">State *</label>
                <select
                  id="state_id"
                  className="form-control"
                  name="state_id"
                  value={formData.state_id !== null ? formData.state_id : ""}
                  onChange={handleChange}
                >
                  <option value="" key="">
                    Please Select
                  </option>
                  {/* Render the professions */}
                  {states.map((state) => (
                    <option key={state.id} value={state.id}>
                      {state.name}
                    </option>
                  ))}
                </select>
                {errors.state_id && <span className="error">{errors.state_id}</span>}
              </div>

              <div className="mb-2 col-md-4">
                <label className="form-label" htmlFor="city_id">
                  City *
                </label>
                <select
                  id="city_id"
                  className="form-control"
                  name="city_id"
                  value={formData.city_id !== null ? formData.city_id : ""}
                  onChange={handleChange}
                >
                  <option value="" key="">
                    Please Select
                  </option>
                  {/* Render the professions */}
                  {cities.map((city) => (
                    <option key={city.id} value={city.id}>
                      {city.city_name}
                    </option>
                  ))}
                </select>
                {errors.city_id && <span className="error">{errors.city_id}</span>}
              </div>

              <div className="col-md-4 mb-2">
                <label className="form-label" htmlFor="shift">
                  Shift
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="shift"
                  name="shift"
                  onChange={handleChange}
                  defaultValue={formData.shift !== null ? formData.shift : ""}
                />
              </div>

              <div className="col-md-4 mb-2">
                <label className="form-label" htmlFor="approved_time_off">
                  Approved Time Off
                </label>
                <input
                  type="date"
                  className="form-control"
                  id="approved_time_off"
                  name="approved_time_off"
                  onChange={handleChange}
                  defaultValue={
                    formData.approved_time_off !== null ? formData.approved_time_off : ""
                  }
                />
              </div>

              <div className="col-md-4 mb-2">
                <label className="form-label" htmlFor="confirmed_with_facility">
                  Confirmed with Facility
                </label>
                <select
                  className="form-control"
                  id="confirmed_with_facility"
                  name="confirmed_with_facility"
                  onChange={handleChange}
                  value={formData.confirmed_with_facility}
                >
                  <option key="1" value="1">
                    Yes
                  </option>
                  <option key="0" value="0">
                    No
                  </option>
                </select>
              </div>

              <div className="col-md-4 mb-2">
                <label className="form-label" htmlFor="confirmed_with_traveler">
                  Confirmed with Traveler
                </label>
                <select
                  className="form-control"
                  id="confirmed_with_traveler"
                  name="confirmed_with_traveler"
                  onChange={handleChange}
                  value={formData.confirmed_with_traveler}
                >
                  <option key="1" value="1">
                    Yes
                  </option>
                  <option key="0" value="0">
                    No
                  </option>
                </select>
              </div>

              <div className="col-md-4 mb-2">
                <label className="form-label" htmlFor="fstatus">
                  Status
                </label>
                <select
                  className="form-control"
                  id="fstatus"
                  name="status"
                  onChange={handleChange}
                  value={formData.status !== null ? formData.status : "1"}
                >
                  <option key="1" value="1">
                    Active
                  </option>
                  <option key="0" value="0">
                    In-Active
                  </option>
                </select>
                {errors.cat_id && <span className="error">{errors.cat_id}</span>}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn btn-secondary" onClick={toggleUploadModal}>
              Close
            </button>
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </Modal.Footer>
        </form>
      </Modal>
      {isLoading === true && <Spinner />}
    </Layout>
  );
};

export default Assignments;

import React, { useEffect, useState } from "react";
import Layout from "../../Layout/Index";
import { getTokenData } from "../../../utils/tokenUtils";
import * as api from "../../../services/ApiService";
import Spinner from "../../Loader/Spinner";
import { toast } from "react-toastify";
import { formatDate } from "../../../utils/dateFormat";
import { useFormik } from "formik";
import * as Yup from "yup";
import Modal from "react-bootstrap/Modal";
import PasswordToggle from "../../../hooks/PasswordToggle";
import PageTitle from "../../Layout/PageTitle";
import { Tooltip } from "react-tooltip";
import useConfirm from "../../../hooks/useConfirm";

const SubAdmin = () => {
  let user = getTokenData();

  /* Filters function Start */
  const [showFilterDropdown, setFilterDropdown] = useState(false);
  const handleToggleFilterDropDown = () => {
    setFilterDropdown(!showFilterDropdown);
  };

  // State for filter values
  const [filters, setFilters] = useState({
    keyword: "",
    status: "all",
  });

  // Handle form reset
  const handleReset = () => {
    const defaultFilters = {
      keyword: "",
      status: "all",
    };
    setFilters(defaultFilters);
    setFilterDropdown(false);
  };

  useEffect(() => {
    if (showFilterDropdown === false) {
      fetchProfessions();
    }
  }, [showFilterDropdown]);

  // Handle form input change
  const handleFilterInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setFilters({
        ...filters,
        [name]: checked,
      });
    } else {
      setFilters({
        ...filters,
        [name]: value,
      });
    }
  };

  // Handle form submit
  const handleFilterSubmit = (e) => {
    e.preventDefault();
    setFilterDropdown(false);
  };
  /* Filters function End */

  const [isLoading, setIsLoading] = useState(false);
  const [subAdminData, setSubAdminData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editId, setEditId] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const fetchProfessions = async () => {
    try {
      const param = {
        user_id: user.id,
        role_id: 7,
      };
      const queryParams = { ...param, ...filters };
      const apiData = await api.postMethod(queryParams, "admin/get-sub-admins");

      setSubAdminData(apiData.data);
      setLoading(false);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchProfessions();
  }, []);

  // validation with formik
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      email: Yup.string().email("Invalid Email").required("Email is required"),
      password: Yup.string().required("Password is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      try {
        const params = {
          name: values.name,
          email: values.email,
          password: values.password,
          phone: values.phone,
          user_id: user.id,
        };
        if (editId) {
          params.id = editId;
        }
        const result = await api.postMethod(params, "admin/update-sub-admin");
        if (result.status) {
          fetchProfessions();
          resetForm();
          setEditId(null);
          setShowModal(false);
          toast.success(result.message);
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        console.error("Error submitting data:", error);
      } finally {
        setIsLoading(false);
      }
    },
  });

  const handleEdit = (profession) => {
    formik.setValues({
      name: profession.name,
      email: profession.email,
      password: profession.password,
      phone: profession.phone,
    });
    setEditId(profession.id);
    setShowModal(true); // Show the modal
  };

  const { confirm } = useConfirm();
  const handleDelete = async (profession) => {
    const confirmed = await confirm();
    if (confirmed.isConfirmed) {
      profession.user_id = user.id;
      setIsLoading(true);
      const result = await api.postMethod(profession, "admin/delete-sub-admin");
      setIsLoading(false);
      if (result.status) {
        toast.success(result.message);
        fetchProfessions();
      }
    }
  };

  /* Select All checkbox functions start */

  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [bulkAction, setBulkAction] = useState("");

  const handleSelectAllChange = (event) => {
    if (event.target.checked) {
      const allUserIds = subAdminData.map((record) => record.id);
      setSelectedUserIds(allUserIds);
    } else {
      setSelectedUserIds([]);
    }
  };

  const handleCheckboxChange = (event) => {
    const userId = parseInt(event.target.value);
    setSelectedUserIds((prevSelectedUserIds) => {
      if (event.target.checked) {
        // Add the user ID to the list
        return [...prevSelectedUserIds, userId];
      } else {
        // Remove the user ID from the list
        return prevSelectedUserIds.filter((id) => id !== userId);
      }
    });
  };

  const handleBulkActionChange = (event) => {
    setBulkAction(event.target.value);
  };

  const handleBulkActionSubmit = async () => {
    if (bulkAction === "") {
      toast.error("Please any action from bulk actions");
    } else if (selectedUserIds.length === 0) {
      toast.error("Please select atleast 1 record to perform bulk action");
    } else {
      try {
        setIsLoading(true);
        const param = {
          user_id: user.id,
          user_ids: selectedUserIds,
          bulk_action: bulkAction,
          role_id: 7,
        };
        const result = await api.postFileMethod(param, "admin/user-bulk-actions");
        setIsLoading(false);
        if (result.status) {
          toast.success(result.message);
          setSelectedUserIds([]);
          setBulkAction("");
          fetchProfessions();
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  const updateStatus = async (id, status) => {
    try {
      setIsLoading(true);
      const param = {
        user_id: user.id,
        id: id,
        status: status,
        role_id: 7,
      };
      const result = await api.postFileMethod(param, "admin/update-user-status");
      setIsLoading(false);
      if (result.status) {
        toast.success(result.message);
        fetchProfessions();
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  // Password toggle state
  const [showPassword, togglePasswordVisibility] = PasswordToggle(formik.values, formik.setValues);
  const breadcrumbItems = [
    { text: "Admin Dashboard", link: "/admin/dashboard" },
    { text: "Sub Admins", link: null },
  ];

  return (
    <Layout>
      <PageTitle title="Sub Admins" breadcrumbItems={breadcrumbItems} />

      {/* end page title */}
      <div className="row">
        <div className="col-12">
          <div className="card custom-box-shadow">
            <div className="card-body">
              <div className="row justify-content-between">
                <div className="col-md-6">
                  <div className="">
                    <select
                      className="form-select my-1 my-lg-0 w-auto d-inline me-1"
                      value={bulkAction}
                      onChange={handleBulkActionChange}
                    >
                      <option value="">Bulk Actions</option>
                      <option value="delete">Delete</option>
                      <option value="change-status-active">Change Status to Active</option>
                      <option value="change-status-inactive">Change Status to In-Active</option>
                    </select>
                    <button onClick={handleBulkActionSubmit} className="btn btn-info">
                      Apply Action
                    </button>
                  </div>
                </div>
                <div className="col-auto">
                  <div className="dropdown" style={{ width: 300 }}>
                    <button
                      type="button"
                      className="btn btn-outline-light dropdown-toggle w-100 text-start"
                      onClick={handleToggleFilterDropDown}
                    >
                      Filters
                      <i className="mdi mdi-chevron-down float-end" />
                    </button>
                    <div className={`dropdown-menu ${showFilterDropdown ? "show" : ""}`}>
                      <form className="px-2 py-2" onSubmit={handleFilterSubmit}>
                        <div className="mb-2">
                          <label htmlFor="keyword" className="form-label">
                            Keyword
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="keyword"
                            name="keyword"
                            value={filters.keyword}
                            onChange={handleFilterInputChange}
                          />
                        </div>

                        <div className="mb-2">
                          <label htmlFor="status" className="form-label">
                            Status
                          </label>
                          <select
                            className="form-select"
                            id="cstatus"
                            name="status"
                            value={filters.status}
                            onChange={handleFilterInputChange}
                          >
                            <option value={"all"}>Please Select</option>
                            <option value={1}>Active</option>
                            <option value={0}>In-Active</option>
                          </select>
                        </div>

                        <div className="text-end mt-1">
                          <button
                            type="button"
                            className="btn btn-light float-start"
                            onClick={handleReset}
                          >
                            Reset Filter
                          </button>
                          <button type="submit" className="btn btn-primary">
                            Apply Filter
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="col-auto">
                  <div className="text-lg-end">
                    <button
                      type="button"
                      className="btn btn-primary waves-effect waves-light mb-2"
                      onClick={() => setShowModal(true)}
                    >
                      <i className="fa fa-plus mr-1" /> Add New
                    </button>
                  </div>
                </div>
                {/* end col*/}
              </div>
              <div className="table-responsive">
                <table className="table table-centered table-nowrap mb-0">
                  <thead className="table-light">
                    <tr>
                      <th className="form-checks">
                        <input
                          type="checkbox"
                          onChange={handleSelectAllChange}
                          checked={
                            selectedUserIds.length === subAdminData.length &&
                            subAdminData.length > 0
                          }
                          className="form-check-input"
                        />
                      </th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Phone Number</th>
                      <th>Status</th>
                      <th>Registered On</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan="7">Loading...</td>
                      </tr>
                    ) : subAdminData.length === 0 ? (
                      <tr>
                        <td colSpan="7">No records found</td>
                      </tr>
                    ) : (
                      subAdminData.map((value) => (
                        <tr key={value.id}>
                          <td className="form-checks">
                            <input
                              type="checkbox"
                              value={value.id}
                              name="user_ids"
                              onChange={handleCheckboxChange}
                              checked={selectedUserIds.includes(value.id)}
                              className="form-check-input"
                            />
                          </td>
                          <td>{value.name}</td>
                          <td>{value.email}</td>
                          <td>{value.phone || "-"}</td>
                          <td>
                            {value.status == 1 && (
                              <label className="badge badge-soft-success">Active</label>
                            )}
                            {value.status == 0 && (
                              <label className="badge badge-soft-warning">In-Active</label>
                            )}
                            {value.status == 2 && (
                              <label className="badge badge-soft-danger">Blocked</label>
                            )}
                            {value.status == 3 && (
                              <label className="badge badge-soft-info">Terminate</label>
                            )}
                          </td>
                          <td>
                            {formatDate(value.created_at, "MMM DD, YYYY")}{" "}
                            <small className="text-muted">
                              {formatDate(value.created_at, "hh:mm A")}
                            </small>
                          </td>
                          <td>
                            {value.status == 0 && (
                              <>
                                <button
                                  type="button"
                                  className="btn btn-default action-icon"
                                  data-tooltip-id="mt-1"
                                  data-tooltip-content="In-Active"
                                  onClick={() => updateStatus(value.id, 1)}
                                >
                                  <i className="mdi mdi-close-box-outline" />
                                </button>
                                <Tooltip id="mt-1"></Tooltip>
                              </>
                            )}
                            {value.status == 1 && (
                              <>
                                <button
                                  type="button"
                                  className="btn btn-default action-icon"
                                  data-tooltip-id="mt-1"
                                  data-tooltip-content="Active"
                                  onClick={() => updateStatus(value.id, 0)}
                                >
                                  <i className="mdi mdi-check-box-outline" />
                                </button>
                                <Tooltip id="mt-1"></Tooltip>
                              </>
                            )}

                            <button
                              type="button"
                              className="btn btn-default action-icon"
                              onClick={() => handleEdit(value)}
                            >
                              <i className="mdi mdi-pencil" />
                            </button>
                            <button
                              type="button"
                              className="btn btn-default action-icon"
                              onClick={() => handleDelete(value)}
                            >
                              <i className="mdi mdi-delete" />
                            </button>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            {/* end card-body*/}
          </div>
          {/* end card*/}
        </div>
        {/* end col */}
      </div>

      {/* Add Employee Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)} centered backdrop="static">
        <form onSubmit={formik.handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title className="my-0">
              {editId ? "Edit Sub Admin" : "Add Sub Admin"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group mb-2">
              <label htmlFor="name" className="form-label">
                Full Name *
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.name && formik.errors.name ? (
                <div className="error">{formik.errors.name}</div>
              ) : null}
            </div>

            <div className="form-group mb-2">
              <label htmlFor="email" className="form-label">
                Email *
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                readOnly={editId ? true : false}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="error">{formik.errors.email}</div>
              ) : null}
            </div>

            <div className="form-group mb-2">
              <label htmlFor="phone" className="form-label">
                Phone
              </label>
              <input
                type="text"
                className="form-control"
                id="phone"
                name="phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              {formik.touched.phone && formik.errors.phone ? (
                <div className="error">{formik.errors.phone}</div>
              ) : null}
            </div>

            <div className="form-group mb-2">
              <label htmlFor="password" className="form-label">
                Password *
              </label>
              <div className="input-group input-group-merge">
                <input
                  type={showPassword ? "text" : "password"}
                  className="form-control"
                  id="password"
                  name="password"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <div
                  className={
                    showPassword ? "show-password input-group-append" : "input-group-append"
                  }
                  data-password="false"
                >
                  <div className="input-group-text">
                    <span className="password-eye" onClick={togglePasswordVisibility}></span>
                  </div>
                </div>
              </div>
              {formik.touched.password && formik.errors.password ? (
                <div className="error">{formik.errors.password}</div>
              ) : null}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-secondary waves-effect"
              onClick={() => setShowModal(false)}
            >
              Close
            </button>
            <button
              type="submit"
              className="btn btn-success waves-effect waves-light"
              disabled={isLoading}
            >
              Submit <i className="fa fa-check" />
            </button>
          </Modal.Footer>
        </form>
      </Modal>

      {isLoading === true && <Spinner />}
    </Layout>
  );
};

export default SubAdmin;

import React, { useState, useEffect } from "react";
import * as tokenUtils from "../../../../utils/tokenUtils";
import * as api from "../../../../services/ApiService";

function ProfessionalInfo({ profileInfo, formatDate }) {
  // Get data from token util
  let user = tokenUtils.getTokenData();

  const [workHistories, setWorkHisstories] = useState({});
  const [loading, setLoading] = useState(true);
  const [noRecordsFound, setNoRecordsFound] = useState(false);

  const fetchWorkHistoryData = async () => {
    try {
      const param = {
        user_id: profileInfo.id,
        token: user.token,
      };
      const apiData = await api.postMethod(param, "user/get-work-history");
      //const apiDataObj = Object.setPrototypeOf(apiData, Object.prototype);

      /*
      const apiDataObj = apiData.reduce((acc, currentValue, index) => {
        acc[index] = currentValue;
        return acc;
      }, {});
      */

      setWorkHisstories(apiData.data);
      setLoading(false);
      // Check if data array is empty
      if (apiData.data.length === 0) {
        setNoRecordsFound(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchWorkHistoryData();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  return (
    <>
      <div className="row">
        <div className="col-md-4">
          <h4 className="font-13 text-capitalize mb-1 fw-bold">Total Years of Experience :</h4>
          <p className="mb-3">
            {profileInfo.total_experience ? profileInfo.total_experience : "-"}
          </p>
        </div>

        <div className="clearfix"></div>
        <div className="col-md-12">
          <div className="table-responsive">
            <table className="table table-centered table-nowrap mb-0">
              <thead className="table-light">
                <tr>
                  <th>Company/Organization</th>
                  <th align="center" className="text-center">
                    Start/End Date
                  </th>
                  <th align="center" className="text-center">
                    Profession/Specialty
                  </th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan="3">Loading...</td>
                  </tr>
                ) : (
                  <>
                    {noRecordsFound ? (
                      <tr>
                        <td colSpan="3">No records found</td>
                      </tr>
                    ) : (
                      <>
                        {Object.entries(workHistories).map(([key, value]) => (
                          <tr key={value.id}>
                            <td>
                              {value.title}
                              {value.company_name && " - " + value.company_name}
                              <p className="mb-0 text-muted">
                                <small>
                                  {value.state_code !== null && value.city_name !== null
                                    ? value.city_name + ", " + value.state_code
                                    : value.state_code !== null
                                    ? value.state_code
                                    : value.city_name !== null
                                    ? value.city_name
                                    : ""}
                                </small>
                              </p>
                            </td>
                            <td align="center">
                              {value.start_month}/{value.start_year} -{" "}
                              {value.currently_working === "1"
                                ? "Currently Working"
                                : value.end_month && value.end_year
                                ? value.end_month + "/" + value.end_year
                                : "N/A"}
                            </td>
                            <td align="center">
                              {value.profession !== null && value.specialty !== null
                                ? value.profession + "/" + value.specialty
                                : value.profession !== null
                                ? value.profession
                                : value.specialty !== null
                                ? value.profession
                                : "-"}
                            </td>
                          </tr>
                        ))}
                      </>
                    )}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProfessionalInfo;

import React, { useState, useEffect } from "react";
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import Sidebar from "../../Layout/Sidebar";
import ThemeSettings from "../../Layout/ThemeSettings";
import { useParams, Link, useNavigate } from "react-router-dom";
import * as api from "../../../services/ApiService";
import { Tooltip } from "react-tooltip";
import * as tokenUtils from "../../../utils/tokenUtils";
import * as Yup from "yup";
import Spinner from "../../Loader/Spinner";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import PageTitle from "../../Layout/PageTitle";
import { currentRole } from "../../Layout/HelmetComponent";
import { formatDate } from "../../../utils/dateFormat";
import cloneDeep from "lodash/cloneDeep";

function ComplianceAddChecklist() {
  let navigate = useNavigate();

  const { checklistID } = useParams();

  // Get data from token util
  let user = tokenUtils.getTokenData();
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    id: null,
    user_id: user.id,
    token: user.token,
    title: "",
    status: "",
    sections: [
      {
        sectionTitle: "",
        skills: [
          {
            skillTitle: "",
            options: {
              proficiency: [{ title: "", value: "" }],
              frequency: [{ title: "", value: "" }],
            },
          },
        ],
      },
    ],
  });

  const processOptions = (options) => {
    const maxLength = Math.max(options.proficiency?.length || 0, options.frequency?.length || 0);

    const fillOptions = (optionsArray) => {
      const filledOptions = optionsArray.map((option) => ({
        title: option.title || "",
        value: option.value || "",
      }));

      while (filledOptions.length < maxLength) {
        filledOptions.push({ title: "", value: "" });
      }

      return filledOptions;
    };

    return {
      proficiency: fillOptions(options.proficiency || []),
      frequency: fillOptions(options.frequency || []),
    };
  };

  const handleDataFetch = (apiData) => {
    const sections = JSON.parse(apiData.data.checklist_meta);

    const updatedSections = sections.map((section) => {
      return {
        ...section,
        skills: section.skills.map((skill) => ({
          ...skill,
          options: processOptions(skill.options || {}),
        })),
      };
    });

    setFormData((prevState) => ({
      ...prevState,
      sections: updatedSections,
      id: apiData.data.id,
      status: apiData.data.status,
      user_id: user.id,
      token: user.token,
      title: apiData.data.title,
    }));
  };

  const fetchUserDetails = async () => {
    try {
      const param = {
        user_id: user.id,
        token: user.token,
        checklistID: checklistID,
      };
      const apiData = await api.postMethod(param, "admin/get-compliance-checklist-details");

      if (apiData.status) {
        handleDataFetch(apiData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchUserDetails();
  }, [checklistID]); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const handleTitleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    validateFields(name, value);
  };

  const handleChange = (e, sectionIndex, skillIndex, fieldType, optionIndex) => {
    const { name, value } = e.target;

    const updatedFormData = { ...formData }; // Make a shallow copy

    // Ensure the sections array is initialized
    if (!updatedFormData.sections[sectionIndex]) {
      updatedFormData.sections[sectionIndex] = {
        sectionTitle: "",
        skills: [
          {
            skillTitle: "",
            options: {
              proficiency: [{ title: "", value: "" }],
              frequency: [{ title: "", value: "" }],
            },
          },
        ],
      };
    }

    // Ensure the skills array is initialized
    if (!updatedFormData.sections[sectionIndex].skills) {
      updatedFormData.sections[sectionIndex].skills = [
        {
          skillTitle: "",
          options: {
            proficiency: [{ title: "", value: "" }],
            frequency: [{ title: "", value: "" }],
          },
        },
      ];
    }

    // Ensure the specific skill at skillIndex is initialized
    if (!updatedFormData.sections[sectionIndex].skills[skillIndex]) {
      updatedFormData.sections[sectionIndex].skills[skillIndex] = {
        skillTitle: "",
        options: {
          proficiency: [{ title: "", value: "" }],
          frequency: [{ title: "", value: "" }],
        },
      };
    }

    // Ensure the options object and arrays are initialized
    if (!updatedFormData.sections[sectionIndex].skills[skillIndex].options) {
      updatedFormData.sections[sectionIndex].skills[skillIndex].options = {
        proficiency: [{ title: "", value: "" }],
        frequency: [{ title: "", value: "" }],
      };
    }

    if (!updatedFormData.sections[sectionIndex].skills[skillIndex].options[fieldType]) {
      updatedFormData.sections[sectionIndex].skills[skillIndex].options[fieldType] = [
        { title: "", value: "" },
      ];
    }

    // Ensure the specific item at optionIndex is initialized
    if (
      !updatedFormData.sections[sectionIndex].skills[skillIndex].options[fieldType][optionIndex]
    ) {
      updatedFormData.sections[sectionIndex].skills[skillIndex].options[fieldType][optionIndex] = {
        title: "",
        value: "",
      };
    }

    console.log(fieldType);

    if (fieldType === "section") {
      updatedFormData.sections[sectionIndex][name] = value;
    } else if (fieldType === "skill") {
      updatedFormData.sections[sectionIndex].skills[skillIndex][name] = value;
    } else {
      updatedFormData.sections[sectionIndex].skills[skillIndex].options[fieldType][optionIndex][
        name
      ] = value;
    }
    setFormData(updatedFormData);
  };

  /*
  const handleChange = (e, sectionIndex, skillIndex, fieldType, optionIndex) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => {
      //const updatedFormData = { ...prevFormData };
      const updatedFormData = cloneDeep(prevFormData);

      // Ensure the sections array is initialized
      if (!updatedFormData.sections[sectionIndex]) {
        updatedFormData.sections[sectionIndex] = {
          sectionTitle: "",
          skills: [
            {
              skillTitle: "",
              options: {
                proficiency: [{ title: "", value: "" }],
                frequency: [{ title: "", value: "" }],
              },
            },
          ],
        };
      }

      // Ensure the skills array is initialized
      if (!updatedFormData.sections[sectionIndex].skills) {
        updatedFormData.sections[sectionIndex].skills = [
          {
            skillTitle: "",
            options: {
              proficiency: [{ title: "", value: "" }],
              frequency: [{ title: "", value: "" }],
            },
          },
        ];
      }

      // Ensure the specific skill at skillIndex is initialized
      if (!updatedFormData.sections[sectionIndex].skills[skillIndex]) {
        updatedFormData.sections[sectionIndex].skills[skillIndex] = {
          skillTitle: "",
          options: {
            proficiency: [{ title: "", value: "" }],
            frequency: [{ title: "", value: "" }],
          },
        };
      }

      // Ensure the options object and arrays are initialized
      if (!updatedFormData.sections[sectionIndex].skills[skillIndex].options) {
        updatedFormData.sections[sectionIndex].skills[skillIndex].options = {
          proficiency: [{ title: "", value: "" }],
          frequency: [{ title: "", value: "" }],
        };
      }

      if (!updatedFormData.sections[sectionIndex].skills[skillIndex].options[fieldType]) {
        updatedFormData.sections[sectionIndex].skills[skillIndex].options[fieldType] = [
          { title: "", value: "" },
        ];
      }

      // Ensure the specific item at optionIndex is initialized
      if (
        !updatedFormData.sections[sectionIndex].skills[skillIndex].options[fieldType][optionIndex]
      ) {
        updatedFormData.sections[sectionIndex].skills[skillIndex].options[fieldType][optionIndex] =
          {
            title: "",
            value: "",
          };
      }

      if (fieldType === "section") {
        updatedFormData.sections[sectionIndex][name] = value;
      } else if (fieldType === "skill") {
        updatedFormData.sections[sectionIndex].skills[skillIndex][name] = value;
      } else {
        updatedFormData.sections[sectionIndex].skills[skillIndex].options[fieldType][optionIndex][
          name
        ] = value;
      }

      return updatedFormData;
    });
  };
  */

  const addSection = () => {
    const updatedFormData = { ...formData }; // Make a shallow copy
    updatedFormData.sections.push({
      sectionTitle: "",
      skills: [
        {
          skillTitle: "",
          options: {
            proficiency: [{ title: "", value: "" }],
            frequency: [{ title: "", value: "" }],
          },
        },
      ],
    });
    setFormData(updatedFormData);
  };

  const addSkill = (sectionIndex) => {
    const updatedFormData = { ...formData }; // Make a shallow copy
    updatedFormData.sections[sectionIndex].skills.push({
      skillTitle: "",
      options: {
        proficiency: [{ title: "", value: "" }],
        frequency: [{ title: "", value: "" }],
      },
    });
    setFormData(updatedFormData);
  };

  const addOption = (sectionIndex, skillIndex) => {
    const updatedFormData = { ...formData }; // Make a shallow copy
    updatedFormData.sections[sectionIndex].skills[skillIndex].options.proficiency.push({
      title: "",
      value: "",
    });
    updatedFormData.sections[sectionIndex].skills[skillIndex].options.frequency.push({
      title: "",
      value: "",
    });
    setFormData(updatedFormData);
  };

  const [errors, setErrors] = useState({});

  //Define the validation schema
  const validationSchema = Yup.object({
    title: Yup.string().required("Title is Required"),
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await validationSchema.validate(formData, { abortEarly: false });

      setIsLoading(true);

      const dataToSend = {
        ...formData,
        sections: JSON.stringify(formData.sections),
      };
      const result = await api.postFileMethod(dataToSend, "admin/update-compliance-checklist");
      setIsLoading(false);
      if (result.status) {
        toast.success(result.message);
        navigate("/admin/compliance-checklists");
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      setIsLoading(false);
      const newErrors = {};
      if (error.inner && Array.isArray(error.inner)) {
        error.inner.forEach((element) => {
          newErrors[element.path] = element.message;
        });
      } else {
        console.error("Unexpected error structure:", error);
      }
      setErrors(newErrors);
    }
  };

  const validateFields = (name, value) => {
    try {
      validationSchema.validateSyncAt(name, { [name]: value });

      // Clear the error for the current field if validation passes
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    } catch (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error.message,
      }));
    }
  };

  /* Delete actions start */

  const deleteSection = (sectionIndex) => {
    const updatedFormData = { ...formData };
    updatedFormData.sections.splice(sectionIndex, 1);
    setFormData(updatedFormData);
  };

  const deleteSkill = (sectionIndex, skillIndex) => {
    const updatedFormData = { ...formData };
    updatedFormData.sections[sectionIndex].skills.splice(skillIndex, 1);
    setFormData(updatedFormData);
  };

  const deleteOption = (sectionIndex, skillIndex, optionIndex) => {
    const updatedFormData = { ...formData };

    // Remove the option at optionIndex from both proficiency and frequency
    updatedFormData.sections[sectionIndex].skills[skillIndex].options.proficiency.splice(
      optionIndex,
      1
    );
    updatedFormData.sections[sectionIndex].skills[skillIndex].options.frequency.splice(
      optionIndex,
      1
    );

    setFormData(updatedFormData);
  };

  const breadcrumbItems = [
    { text: "Admin Dashboard", link: "/admin/dashboard" },
    { text: "Compliance Checklists", link: "/admin/compliance-checklists" },
    { text: "Add Compliance Checklist", link: null },
  ];

  return (
    <>
      <div id="wrapper">
        <Sidebar />

        <div className="content-page">
          <Header />
          <div className="content">
            {/* Start Content*/}
            <div className="container-fluid">
              {/* start page title */}
              <PageTitle title="Add Compliance Checklists" breadcrumbItems={breadcrumbItems} />

              <div className="row">
                <div className="col-12">
                  <form onSubmit={handleSubmit}>
                    <div className="card custom-box-shadow">
                      <div className="card-body pb-0">
                        <div className="form-group mb-3">
                          <div className="row">
                            <div className="col-md-6">
                              <label htmlFor="title" className="mb-1">
                                Title *
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="title"
                                value={formData.title}
                                onChange={handleTitleChange}
                              />
                              {errors.title && <span className="error">{errors.title}</span>}
                            </div>
                            <div className="col-md-6">
                              <label htmlFor="status" className="mb-1">
                                Status
                              </label>
                              <select
                                className="form-control"
                                name="status"
                                value={formData.status}
                                onChange={handleTitleChange}
                              >
                                <option value="1">Active</option>
                                <option value="0">In-Active</option>
                                <option value="2">Draft</option>
                              </select>
                            </div>
                          </div>
                        </div>

                        {/* Sections */}
                        {formData.sections.map((section, sectionIndex) => {
                          // Ensure at least one skill exists in each section
                          if (!Array.isArray(section.skills) || section.skills.length === 0) {
                            section.skills = [
                              {
                                skillTitle: "",
                                options: {
                                  proficiency: [{ title: "", value: "" }],
                                  frequency: [{ title: "", value: "" }],
                                },
                              },
                            ];
                          }

                          return (
                            <div key={`section_` + sectionIndex} className="form-group mb-3">
                              <div className="card border mb-0">
                                <div className="card-header border-bottom">
                                  <h5 className="card-title">
                                    Section {sectionIndex + 1}
                                    <button
                                      type="button"
                                      className="btn btn-danger btn-xs float-end mt-n1"
                                      onClick={() => deleteSection(sectionIndex)}
                                      disabled={formData.sections.length === 1} // Disable if only one section remains
                                    >
                                      <i className="fa fa-trash"></i>
                                    </button>
                                  </h5>

                                  <input
                                    id={`section_` + sectionIndex + `_title`}
                                    placeholder="Section Title"
                                    className="form-control"
                                    type="text"
                                    name="sectionTitle"
                                    value={section.sectionTitle || ""}
                                    onChange={(e) =>
                                      handleChange(e, sectionIndex, null, "section", null)
                                    }
                                  />
                                </div>

                                <div className="card-body pb-0">
                                  {section.skills.map((skill, skillIndex) => {
                                    const proficiencyOptions = skill.options?.proficiency || [];
                                    const frequencyOptions = skill.options?.frequency || [];

                                    // Determine the maximum number of options
                                    const maxOptions = Math.max(
                                      proficiencyOptions.length,
                                      frequencyOptions.length
                                    );

                                    // Fill in missing options with empty values
                                    const filledProficiencyOptions = proficiencyOptions.slice();
                                    const filledFrequencyOptions = frequencyOptions.slice();

                                    for (let i = proficiencyOptions.length; i < maxOptions; i++) {
                                      filledProficiencyOptions.push({ title: "", value: "" });
                                    }

                                    for (let i = frequencyOptions.length; i < maxOptions; i++) {
                                      filledFrequencyOptions.push({ title: "", value: "" });
                                    }

                                    return (
                                      <div
                                        key={`skill_` + sectionIndex + "_" + skillIndex}
                                        className="card border mb-3"
                                      >
                                        <div className="card-header border-bottom">
                                          <h5 className="card-title">
                                            Skill List {skillIndex + 1}
                                            <button
                                              type="button"
                                              className="btn btn-danger btn-xs float-end mt-n1"
                                              onClick={() => deleteSkill(sectionIndex, skillIndex)}
                                              disabled={section.skills.length === 1} // Disable if only one skill remains
                                            >
                                              <i className="fa fa-trash"></i>
                                            </button>
                                          </h5>
                                          <input
                                            id={
                                              `skill_` + sectionIndex + "_" + skillIndex + `_title`
                                            }
                                            type="text"
                                            name="skillTitle"
                                            placeholder="Skill Title"
                                            className="form-control"
                                            value={skill.skillTitle || ""}
                                            onChange={(e) =>
                                              handleChange(
                                                e,
                                                sectionIndex,
                                                skillIndex,
                                                "skill",
                                                null
                                              )
                                            }
                                          />
                                        </div>

                                        <div className="card-body pb-0">
                                          <div className="row">
                                            <div className="col-md-6">
                                              <h5
                                                className="mt-0 card-title"
                                                style={{ textTransform: "capitalize" }}
                                              >
                                                Proficiency Options
                                              </h5>
                                              {filledProficiencyOptions.map(
                                                (option, optionIndex) => (
                                                  <div
                                                    key={
                                                      `proficiency_opt_` +
                                                      sectionIndex +
                                                      "_" +
                                                      skillIndex +
                                                      "_" +
                                                      optionIndex
                                                    }
                                                    className="row mb-3"
                                                  >
                                                    <div className="col-md-6">
                                                      <div className="input-group">
                                                        <span className="input-group-text">
                                                          Title
                                                        </span>
                                                        <input
                                                          id={
                                                            `proficiency_opt_` +
                                                            sectionIndex +
                                                            "_" +
                                                            skillIndex +
                                                            "_" +
                                                            optionIndex +
                                                            "_title"
                                                          }
                                                          className="form-control"
                                                          type="text"
                                                          name="title"
                                                          value={option.title || ""}
                                                          onChange={(e) =>
                                                            handleChange(
                                                              e,
                                                              sectionIndex,
                                                              skillIndex,
                                                              "proficiency",
                                                              optionIndex
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                      <div className="input-group">
                                                        <span className="input-group-text">
                                                          Value
                                                        </span>
                                                        <input
                                                          id={
                                                            `proficiency_opt_` +
                                                            sectionIndex +
                                                            "_" +
                                                            skillIndex +
                                                            "_" +
                                                            optionIndex +
                                                            "_value"
                                                          }
                                                          className="form-control"
                                                          type="text"
                                                          name="value"
                                                          value={option.value || ""}
                                                          onChange={(e) =>
                                                            handleChange(
                                                              e,
                                                              sectionIndex,
                                                              skillIndex,
                                                              "proficiency",
                                                              optionIndex
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                )
                                              )}
                                            </div>

                                            <div className="col-md-6">
                                              <h5
                                                className="mt-0 card-title"
                                                style={{ textTransform: "capitalize" }}
                                              >
                                                Frequency Options
                                              </h5>
                                              {filledFrequencyOptions.map((option, optionIndex) => (
                                                <div
                                                  key={
                                                    `frequency_opt_` +
                                                    sectionIndex +
                                                    "_" +
                                                    skillIndex +
                                                    "_" +
                                                    optionIndex
                                                  }
                                                  className="row mb-3"
                                                >
                                                  <div className="col-md-6">
                                                    <div className="input-group">
                                                      <span className="input-group-text">
                                                        Title
                                                      </span>
                                                      <input
                                                        id={
                                                          `frequency_opt_` +
                                                          sectionIndex +
                                                          "_" +
                                                          skillIndex +
                                                          "_" +
                                                          optionIndex +
                                                          "_title"
                                                        }
                                                        className="form-control"
                                                        type="text"
                                                        name="title"
                                                        value={option.title || ""}
                                                        onChange={(e) =>
                                                          handleChange(
                                                            e,
                                                            sectionIndex,
                                                            skillIndex,
                                                            "frequency",
                                                            optionIndex
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                  <div className="col-md-6">
                                                    <div className="input-group">
                                                      <span className="input-group-text">
                                                        Value
                                                      </span>
                                                      <input
                                                        id={
                                                          `frequency_opt_` +
                                                          sectionIndex +
                                                          "_" +
                                                          skillIndex +
                                                          "_" +
                                                          optionIndex +
                                                          "_value"
                                                        }
                                                        className="form-control"
                                                        type="text"
                                                        name="value"
                                                        value={option.value || ""}
                                                        onChange={(e) =>
                                                          handleChange(
                                                            e,
                                                            sectionIndex,
                                                            skillIndex,
                                                            "frequency",
                                                            optionIndex
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                  </div>
                                                  <button
                                                    type="button"
                                                    className="btn btn-danger btn-xs position-absolute py-1 px-0"
                                                    style={{
                                                      right: "-1px",
                                                      width: "25px",
                                                      marginTop: "3px",
                                                    }}
                                                    onClick={() =>
                                                      deleteOption(
                                                        sectionIndex,
                                                        skillIndex,
                                                        optionIndex
                                                      )
                                                    }
                                                    disabled={
                                                      filledProficiencyOptions.length === 1 ||
                                                      filledFrequencyOptions.length === 1
                                                    } // Disable if only one option remains in either array
                                                  >
                                                    <i className="fa fa-trash"></i>
                                                  </button>
                                                </div>
                                              ))}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="card-footer text-end border-top">
                                          <button
                                            className="btn btn-secondary"
                                            type="button"
                                            onClick={() => addOption(sectionIndex, skillIndex)}
                                          >
                                            Add New Option
                                          </button>
                                        </div>
                                      </div>
                                    );
                                  })}
                                </div>

                                <div className="card-footer text-end border-top">
                                  <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={() => addSkill(sectionIndex)}
                                  >
                                    Add Skill
                                  </button>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>

                      <div className="card-footer pt-0">
                        <div className="text-end">
                          <button className="btn btn-secondary" type="button" onClick={addSection}>
                            Add Section
                          </button>
                        </div>

                        <div className="text-lg-end mt-2 pt-2 border-top">
                          <button type="submit" className="btn btn-primary me-1 ">
                            Submit
                          </button>
                        </div>
                      </div>
                      {/* end card-body*/}
                    </div>
                    {/* end card*/}
                  </form>
                </div>
                {/* end col */}
              </div>
              {/* end row */}
            </div>
            {/* container */}
          </div>

          {/* <pre>{JSON.stringify(formData.sections, null, 2)}</pre> */}

          <Footer />
        </div>
      </div>

      <ThemeSettings />
      {isLoading === true && <Spinner />}
    </>
  );
}

export default ComplianceAddChecklist;

import React, { useState, useEffect } from "react";
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import Sidebar from "../../Layout/Sidebar";
import ThemeSettings from "../../Layout/ThemeSettings";
import { Link } from "react-router-dom";
import * as api from "../../../services/ApiService";
import { Tooltip } from "react-tooltip";
import * as tokenUtils from "../../../utils/tokenUtils";
import * as Yup from "yup";
import Spinner from "../../Loader/Spinner";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import PageTitle from "../../Layout/PageTitle";
import { currentRole } from "../../Layout/HelmetComponent";
import { formatDate } from "../../../utils/dateFormat";
import useConfirm from "../../../hooks/useConfirm";

function ComplianceChecklists() {
  // Get data from token util
  let user = tokenUtils.getTokenData();

  /* Filters function Start */
  const [showFilterDropdown, setFilterDropdown] = useState(false);
  const handleToggleFilterDropDown = () => {
    setFilterDropdown(!showFilterDropdown);
  };

  // State for filter values
  const [filters, setFilters] = useState({
    keyword: "",
    status: "all",
  });

  // Handle form reset
  const handleReset = () => {
    const defaultFilters = {
      keyword: "",
      status: "all",
    };
    setFilters(defaultFilters);
    setFilterDropdown(false);
  };

  useEffect(() => {
    if (showFilterDropdown === false) {
      fetchComplianceFiles();
    }
  }, [showFilterDropdown]);

  // Handle form input change
  const handleFilterInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setFilters({
        ...filters,
        [name]: checked,
      });
    } else {
      setFilters({
        ...filters,
        [name]: value,
      });
    }
  };

  // Handle form submit
  const handleFilterSubmit = (e) => {
    e.preventDefault();
    setFilterDropdown(false);
  };
  /* Filters function End */

  const [isLoading, setIsLoading] = useState(false);
  const [complianceFiles, setComplianceFiles] = useState({});
  const [loading, setLoading] = useState(true);
  const [noRecordsFound, setNoRecordsFound] = useState(false);

  const fetchComplianceFiles = async (tab) => {
    try {
      const param = {
        user_id: user.id,
        token: user.token,
      };
      const queryParams = { ...param, ...filters };
      const apiData = await api.postMethod(queryParams, "client/get-compliance-checklists");

      setComplianceFiles(apiData.data);
      setLoading(false);
      // Check if data array is empty
      if (apiData.data.length === 0) {
        setNoRecordsFound(true);
      } else setNoRecordsFound(false);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchComplianceFiles();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const updateStatus = async (id, status) => {
    try {
      setIsLoading(true);
      const param = {
        user_id: user.id,
        id: id,
        status: status,
      };
      const result = await api.postFileMethod(param, "client/update-compliance-checklist-status");
      setIsLoading(false);
      if (result.status) {
        toast.success(result.message);
        fetchComplianceFiles();
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const cloneChecklist = async (id) => {
    try {
      setIsLoading(true);
      const param = {
        user_id: user.id,
        id: id,
      };
      const result = await api.postFileMethod(param, "client/clone-compliance-checklist");
      setIsLoading(false);
      if (result.status) {
        toast.success(result.message);
        fetchComplianceFiles();
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const { confirm } = useConfirm();
  const handleDelete = async (record) => {
    const confirmed = await confirm();
    if (confirmed.isConfirmed) {
      record.user_id = user.id;
      setIsLoading(true);
      const result = await api.postMethod(record, "client/delete-compliance-checklist");
      setIsLoading(false);
      if (result.status) {
        toast.success(result.message);
        fetchComplianceFiles();
      }
    }
  };

  const breadcrumbItems = [
    { text: currentRole + " Dashboard", link: "/client/dashboard" },
    { text: "Compliance Files", link: "/client/compliance-files" },
    { text: "Compliance Checklists", link: null },
  ];

  return (
    <>
      <div id="wrapper">
        <Sidebar />

        <div className="content-page">
          <Header />
          <div className="content">
            {/* Start Content*/}
            <div className="container-fluid">
              {/* start page title */}
              <PageTitle title="Compliance Checklists" breadcrumbItems={breadcrumbItems} />

              <div className="row">
                <div className="col-12">
                  <div className="card custom-box-shadow">
                    <div className="card-body">
                      <div className="row justify-content-between mb-2">
                        <div className="col-auto">
                          <div className="dropdown" style={{ width: 300 }}>
                            <button
                              type="button"
                              className="btn btn-outline-light dropdown-toggle w-100 text-start"
                              onClick={handleToggleFilterDropDown}
                            >
                              Filters
                              <i className="mdi mdi-chevron-down float-end" />
                            </button>
                            <div className={`dropdown-menu ${showFilterDropdown ? "show" : ""}`}>
                              <form className="px-2 py-2" onSubmit={handleFilterSubmit}>
                                <div className="mb-2">
                                  <label htmlFor="keyword" className="form-label">
                                    Keyword
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="keyword"
                                    name="keyword"
                                    value={filters.keyword}
                                    onChange={handleFilterInputChange}
                                  />
                                </div>

                                <div className="mb-2">
                                  <label htmlFor="status" className="form-label">
                                    Status
                                  </label>
                                  <select
                                    className="form-select"
                                    id="cstatus"
                                    name="status"
                                    value={filters.status}
                                    onChange={handleFilterInputChange}
                                  >
                                    <option value={"all"}>Please Select</option>
                                    <option value={1}>Active</option>
                                    <option value={0}>In-Active</option>
                                    <option value={2}>Draft</option>
                                  </select>
                                </div>

                                <div className="text-end mt-1">
                                  <button
                                    type="button"
                                    className="btn btn-light float-start"
                                    onClick={handleReset}
                                  >
                                    Reset Filter
                                  </button>
                                  <button type="submit" className="btn btn-primary">
                                    Apply Filter
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                        <div className="col-auto">
                          <div className="text-lg-end my-1 my-lg-0">
                            <Link
                              to="/client/add-checklist"
                              className="btn btn-primary waves-effect waves-light mb-2 me-1"
                            >
                              <i className="fa fa-plus mr-1"></i> Add New Checklist
                            </Link>

                            <Link
                              to="/client/completed-checklists"
                              className="btn btn-info waves-effect waves-light mb-2 me-1"
                            >
                              <i className="fa fa-check-square mr-1"></i> Completed Checklists
                            </Link>

                            <Link
                              to="/client/assign-checklist"
                              className="btn btn-info waves-effect waves-light mb-2"
                            >
                              <i className="fa fa-cog mr-1"></i> Assign Checklist
                            </Link>
                          </div>
                        </div>
                        {/* end col*/}
                      </div>
                      <div className="table-responsive">
                        <table className="table table-centered table-nowrap mb-0">
                          <thead className="table-light">
                            <tr>
                              <th>Checklist Title</th>
                              <th>Creation Date</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {loading ? (
                              <tr>
                                <td colSpan="4">Loading...</td>
                              </tr>
                            ) : (
                              <>
                                {noRecordsFound ? (
                                  <tr>
                                    <td colSpan="4">No records found</td>
                                  </tr>
                                ) : (
                                  <>
                                    {Object.entries(complianceFiles).map(([key, value]) => (
                                      <tr key={value.id}>
                                        <td>{value.title}</td>
                                        <td>
                                          {formatDate(value.created_at, "MMM DD, YYYY")}{" "}
                                          <small className="text-muted">
                                            {formatDate(value.created_at, "hh:mm A")}
                                          </small>
                                        </td>
                                        <td>
                                          {value.status == 1 && (
                                            <span className="badge badge-soft-success">Active</span>
                                          )}
                                          {value.status == 0 && (
                                            <span className="badge badge-soft-warning">
                                              In-Active
                                            </span>
                                          )}
                                          {value.status == 2 && (
                                            <span className="badge badge-soft-secondary">
                                              Draft
                                            </span>
                                          )}
                                        </td>
                                        <td>
                                          {value.status == 0 && (
                                            <>
                                              <button
                                                type="button"
                                                className="btn btn-default action-icon"
                                                data-tooltip-id="mt-1"
                                                data-tooltip-content="In-Active"
                                                onClick={() => updateStatus(value.id, 1)}
                                              >
                                                <i className="mdi mdi-close-box-outline" />
                                              </button>
                                              <Tooltip id="mt-1"></Tooltip>
                                            </>
                                          )}
                                          {value.status == 1 && (
                                            <>
                                              <button
                                                type="button"
                                                className="btn btn-default action-icon"
                                                data-tooltip-id="mt-1"
                                                data-tooltip-content="Active"
                                                onClick={() => updateStatus(value.id, 0)}
                                              >
                                                <i className="mdi mdi-check-box-outline" />
                                              </button>
                                              <Tooltip id="mt-1"></Tooltip>
                                            </>
                                          )}

                                          <button
                                            type="button"
                                            className="btn btn-default action-icon"
                                            data-tooltip-id="mt-2"
                                            data-tooltip-content="Clone Compliance Checklist"
                                            onClick={() => cloneChecklist(value.id)}
                                          >
                                            <i className="mdi mdi-content-copy" />
                                          </button>
                                          <Tooltip id="mt-2"></Tooltip>

                                          <Link
                                            to={`/client/add-checklist/` + value.unique_id}
                                            className="btn btn-default action-icon"
                                            data-tooltip-id="mt-3"
                                            data-tooltip-content="Edit Compliance Checklist"
                                          >
                                            <i className="mdi mdi-pencil" />
                                          </Link>
                                          <Tooltip id="mt-3"></Tooltip>

                                          <button
                                            type="button"
                                            className="btn btn-default action-icon"
                                            data-tooltip-id="mt-4"
                                            data-tooltip-content="Remove Compliance Checklist"
                                            onClick={() => handleDelete(value)}
                                          >
                                            <i className="mdi mdi-delete" />
                                          </button>
                                          <Tooltip id="mt-4"></Tooltip>
                                        </td>
                                      </tr>
                                    ))}
                                  </>
                                )}
                              </>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/* end card-body*/}
                  </div>
                  {/* end card*/}
                </div>
                {/* end col */}
              </div>
              {/* end row */}
            </div>
            {/* container */}
          </div>

          <Footer />
        </div>
      </div>

      <ThemeSettings />
      {isLoading === true && <Spinner />}
    </>
  );
}

export default ComplianceChecklists;
